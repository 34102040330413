import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import Modal from "react-modal";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import Text from "react-text";
import { Loading } from "../header/loader.component";
import Swal from "sweetalert2";

class SeatLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      seatCategory: this.props.location.state.seatCategory,

      areacode: this.props.location.state.areacode,
      quantity: this.props.location.state.quantity,
      seats: this.props.location.state.seats,
      ccode: this.props.location.state.cinemaId,
      sessionid: this.props.location.state.sessionId,
      mid: this.props.location.state.mid,
      ttypeCode: this.props.location.state.ttypeCode,
      screenId: "",
      rows: [],
      output: {},
      moviedetail: {},
      showNextButton: 0,
      loading: 1,
      selectedSeats: [],
      ticketPrice: 0,
      prices: [],
      ticketCount: 0,
      transOutput: {},
      seatsToSet: [],
      reserveOutput: {},
      showTrailer: false,
      loading: false,
      agree: false,
      rowSeatValueArr: [],
      doBooking: false,
      bookingsFound:[]
    };
  }
  reserveData = {
    transid: "",
    cinemacode: "",
    sessionid: "",
    reseveSeatVOs: [],
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  // Method to close the modal
  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.removeItem("seatLayoutJson");

    localStorage.removeItem("onlyFood");

    console.log(this.state.seats);
  }
  agree = () => {
    this.setState({ agree: !this.state.agree });
  };
  setSeats = () => {
    this.setState({
      isModalOpen:false
    })
    // alert(this.state.seatCategory);

    if (this.state.ticketCount == this.state.quantity) {
      this.reserveData.transid = "0";
      this.reserveData.cinemaId = this.state.ccode;
      this.reserveData.sessionid = this.state.sessionid;
      this.reserveData.mid = this.state.mid;
      this.reserveData.category = this.state.seatCategory;

      this.reserveData.reseveSeatVOs = this.state.seatsToSet;

      this.reserveData.ttypeCode = this.state.ttypeCode;
      this.reserveData.areaCode = this.state.areacode;
      this.reserveData.doBooking = this.state.doBooking;
      let token = localStorage.getItem("token");
      try {
        this.setState({
          loading: true,
        });
        axios
          .post(
            Constants.api + "content/trans/reserveseats",
            this.reserveData,

            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia("(max-width: 575px)").matches
                  ? "MSITE"
                  : "WEBSITE",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((resp) => {
            console.log(resp);
            console.log("bookingid");

            console.log(this.state.reserveOutput.bookingid);
            if (resp.data.result === "success") {
              console.log(resp.data.output);
              this.setState({
                reserveOutput: resp.data.output,
              });
              localStorage.setItem(
                "bookingid",
                this.state.reserveOutput.bookingid
              );
              localStorage.setItem("transid", this.state.reserveOutput.transid);
              localStorage.setItem(
                "booktype",
                this.state.reserveOutput.booktype
              );
              localStorage.removeItem("onlyFood");
              localStorage.setItem("timeout", resp.data.output.timeout);
              if (resp.data.output.fc == "true") {
                window.location = "/food/" + this.state.ccode;
              } else {
                window.location = "/payment/" + this.state.ccode;
              }
            } else {
              console.log(resp.data.msg);
              // swal(resp.data.msg);
              this.setState({isModalOpen:true,bookingsFound:resp.data.output});
              // Swal.fire({
              //   title: "",
              //   text: resp.data.msg,
              //   icon: "question",
              //   showCancelButton: true,
              //   confirmButtonText: "Yes",
              //   cancelButtonText: "Booking History",
              // }).then((result) => {
              //   if (result.isConfirmed) {
              //     // Call the reserveSeats API with doBooking = false
              //     this.setState({ doBooking: true });
              //     this.setSeats();
              //   } else if (result.dismiss === Swal.DismissReason.cancel) {
              //     // Redirect to /myaccount
              //     window.location.href = "/myaccount";
              //   }
              // });

              this.setState({
                loading: false,
                //selectedSeats: [],
              });
              // setTimeout(function () {
              //   window.location.reload();
              // }, 1500);
            }
          })
          .catch((error) => {
            window.location = "/login";
          });
      } catch (err) {
        console.error(err);
      }

      //         } else {
      //           console.log(resp.data.msg);
      //           swal(resp.data.msg);
      //           this.setState({
      //             loading: 0,
      //           });
      //         }
      //         //movie array
      //       });
      //   } catch (err) {
      //     // Handle Error Here
      //     console.error(err);
      //   }
      // };
    } else {
      {
        localStorage.getItem("language") !== "ar"
          ? // swal({
            //   text: "Please select " + this.state.quantity + " seat", "error"
            //   button: "ok",

            // }):

            // swal({
            //   text: "الرجاء التحديد " + this.state.quantity + " مقعد",
            //   button: "نعم",

            // })

            swal("", "Please select " + this.state.quantity + "seat", "error")
          : swal("", "الرجاء التحديد " + this.state.quantity + "مقعد", "error");
      }
    }
  };
  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "38%",
        padding: "30px 40px 30px 40px",
        borderRadius: "8px",
      },
    };

    const areacode = this.state.areacode;
    const selectSeat = (seatValue, seatNumber, areaNumber, rowSeatValue,j) => {
      const { ticketCount, quantity, selectedSeats, seatsToSet } = this.state;

      // Maximum seat selection check
      if (ticketCount === quantity && !selectedSeats.includes(seatNumber)) {
        localStorage.getItem("language") !== "ar"
          ? swal({
              text: `Please select only ${quantity} seat(s)`,
              button: "OK",
            })
          : swal({
              text: `الرجاء التحديد ${quantity} مقعد`,
              button: "نعم",
            });
        return;
      }

      let updatedSelectedSeats = [...selectedSeats];
      let updatedSeatsToSet = [...seatsToSet];
      const element = document.getElementById(seatValue);

      if (selectedSeats.includes(seatNumber)) {
        // Deselect seat
        updatedSelectedSeats = updatedSelectedSeats.filter(
          (seat) => seat !== seatNumber
        );
        updatedSeatsToSet = updatedSeatsToSet.filter(
          (seat) => seat.seatBookingId !== seatValue
        );
        element?.classList.remove("active");
      } else {
        // Select seat
        updatedSelectedSeats.push(seatNumber);
        updatedSeatsToSet.push({ seatBookingId: seatValue });
        element?.classList.add("active");
      }

      // Update state
      this.setState({
        ticketCount: updatedSelectedSeats.length,
        selectedSeats: updatedSelectedSeats,
        seatsToSet: updatedSeatsToSet,
        showNextButton: updatedSelectedSeats.length > 0 ? 1 : 0,
      });

      console.log("Updated Seats:", updatedSelectedSeats);
    };

    return (
      <>
        {this.state.loading ? <Loading /> : ""}

        <div className="seat-banner d-none-mobile">
          <img src={require("../../assets/seat-banner.png").default} alt="" />
          <h3>
            {" "}
            {localStorage.getItem("language") !== "ar" ? (
              "CHOOSE YOUR SEAT"
            ) : (
              <Text>اختر مقعدًا بجولة</Text>
            )}
            <div className="black-width black-width-info"></div>
          </h3>
        </div>
        <div className="home-content-container">
          <div className="seat-banner-page">
            <div className="seats-info-type">
              <div className="seats-info-box">
                <p>
                  {localStorage.getItem("language") !== "ar" ? (
                    "Available"
                  ) : (
                    <Text>متوفرة</Text>
                  )}
                </p>
                <img src={require("../../assets/available.png").default} />
              </div>
              <div className="seats-info-box">
                <p>
                  {localStorage.getItem("language") !== "ar" ? (
                    "Unavailable"
                  ) : (
                    <Text>غير متوفره</Text>
                  )}
                </p>
                <img src={require("../../assets/unavailable.png").default} />
              </div>
              <div className="seats-info-box">
                <p>
                  {localStorage.getItem("language") !== "ar" ? (
                    "Selected"
                  ) : (
                    <Text>المحدد</Text>
                  )}
                </p>
                <img src={require("../../assets/selected.png").default} />
              </div>
            </div>
            <div className="screen-container">
              <img src={require("../../assets/Group 1810.png").default} />
              <p>
                {localStorage.getItem("language") !== "ar" ? (
                  "SCREEN"
                ) : (
                  <Text>شاشة</Text>
                )}
              </p>
            </div>
            <div className="seats-preview-scroll">
              <div className="seats-preview-container">
                <table>
                  {this.state.seats.map(function (item, i) {
                    return (
                      <>
                        {" "}
                        <tr>
                          <td className="row-name">{item.name}</td>

                          {item.seats.map(function (item2, j, arr) {
                            // console.log(arr[j].id);
                            return (
                              <>
                                <span className="table-width">
                                  <td>
                                    <div
                                      className="seat"
                                      id={item2.passingValue}
                                    >
                                      {item2.display ? (
                                        item2.areacode == areacode ? (
                                          item2.status == "EMPTY" ? (
                                            <>
                                              <p
                                                onClick={() =>
                                                  selectSeat(
                                                    item2.passingValue,
                                                    item2.seatnumber,
                                                    item2.areacode,
                                             
                                                    arr[j].id,
                                                    j
                                                  )
                                                }
                                              >
                                                {item2.seatnumber}
                                              </p>
                                              <img
                                                onClick={() =>
                                                  selectSeat(
                                                    item2.passingValue,
                                                    item2.seatnumber,
                                                    item2.areacode,
                                             

                                                    arr[j].id,
                                                    j
                                                  )
                                                }
                                                src={
                                                  require("../../assets/seat-default.png")
                                                    .default
                                                }
                                                alt={item2.seatnumber}
                                              />
                                              <img
                                                onClick={() =>
                                                  selectSeat(
                                                    item2.passingValue,
                                                    item2.seatnumber,
                                                    item2.areacode,
                                             

                                                    arr[j].id,
                                                    j
                                                  )
                                                }
                                                src={
                                                  require("../../assets/seat-active.png")
                                                    .default
                                                }
                                                alt={item2.seatnumber}
                                              />
                                            </>
                                          ) : (
                                            <img
                                              src={
                                                require("../../assets/seat-disabled.png")
                                                  .default
                                              }
                                              alt={item2.seatnumber}
                                            />
                                          )
                                        ) : (
                                          <img
                                            src={
                                              require("../../assets/seat-disabled.png")
                                                .default
                                            }
                                            alt=""
                                          />
                                        )
                                      ) : (
                                        <> &emsp;</>
                                      )}
                                    </div>
                                  </td>
                                </span>
                              </>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
            <div className="mob-seats-info">
              {/* <p>Selected Seats</p> */}
              <p className="mob-seats-number">
                {this.state.rowSeatValueArr.join(" | ")}
              </p>
            </div>
            <div className="seating-rules-container">
              <div className="seating-container-head">
                {/* <h4>
                  {localStorage.getItem("language") !== "ar" ? (
                    "Seating Rules"
                  ) : (
                    <Text>قواعد الجلوس</Text>
                  )}
                </h4> */}
                {/* <p>
                  {localStorage.getItem("language") !== "ar" ? (
                    "Violating any of the below will result in non-admittance or exiting the cinema, no refunds will be issues if any of these terms are violated."
                  ) : (
                    <Text>
                      سيؤدي انتهاك أي مما يلي إلى عدم الدخول أو الخروج من
                      السينما ، ولن يتم استرداد أي مشكلة في حالة انتهاك أي من
                      هذه الشروط.
                    </Text>
                  )}
                </p> */}
              </div>
              {/* <div className="seating-list">
                <div className="seating-list-part">
                  <ul>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "All people attending this booking (Age categories exempted from MOH vaccination plan -below the age of 12- are excluded) are required to be vaccinated/recovered and present the IMMUNE app or the Kuwait mobile ID app with their status in green/yellow/orange at the entrance of the cinema."
                      ) : (
                        <Text>
                          يجب على جميع الأشخاص الذين يحضرون هذا الحجز (الفئات
                          العمرية المستثناة من خطة التطعيم بوزارة الصحة -
                          استبعاد سن 12 عامًا - أن يتم تطعيمهم / استعادتهم)
                          وتقديم تطبيق منيع أو تطبيق معرف الهاتف المحمول الكويتي
                          مع وضعهم باللون الأخضر / الأصفر / البرتقالي عند مدخل
                          السينما.
                        </Text>
                      )}
                    </li>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "All people attending this booking need to present their original civil ID or Kuwait mobile ID app to verify their identity."
                      ) : (
                        <Text>
                          يجب على جميع الأشخاص الذين يحضرون هذا الحجز تقديم
                          بطاقتهم المدنية الأصلية أو تطبيق معرف الهاتف المحمول
                          الكويتي للتحقق من هويتهم.
                        </Text>
                      )}
                    </li>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Tickets made in this booking are for groups from the same household."
                      ) : (
                        <Text>
                          التذاكر التي تم إجراؤها في هذا الحجز هي لمجموعات من
                          نفس المنزل.
                        </Text>
                      )}
                    </li>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Wearing a mask at all times is mandatory, it is permitted to remove ONLY when eating/drinking inside the theater."
                      ) : (
                        <Text>
                          ارتداء القناع في جميع الأوقات إلزامي ، ولا يجوز خلعه
                          إلا عند الأكل / الشرب داخل المسرح.
                        </Text>
                      )}
                    </li>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Temperature check must be passed at a maximum of 37.5 degree Celsius"
                      ) : (
                        <Text>
                          يجب اجتياز فحص درجة الحرارة بحد أقصى 37.5 درجة مئوية
                        </Text>
                      )}
                    </li>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Moving or changing seats without permission is prohibited"
                      ) : (
                        <Text>يحظر نقل أو تغيير المقاعد بدون إذن</Text>
                      )}
                    </li>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Two-meter Social distancing between your group and others must be maintained at all times."
                      ) : (
                        <Text>
                          يجب الحفاظ على تباعد اجتماعي بطول مترين بين مجموعتك
                          والآخرين في جميع الأوقات.
                        </Text>
                      )}
                    </li>
                  </ul>
                </div>
                <div className="seating-list-part">
                  <ul>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Tickets are available for collection at the cinema using your Booking ID. If you do not have your Booking ID with you, the tickets cannot be issued."
                      ) : (
                        <Text>
                          التذاكر متاحة للتحصيل في السينما باستخدام معرف الحجز
                          الخاص بك. إذا لم يكن لديك معرف الحجز الخاص بك ، فلا
                          يمكن إصدار التذاكر.
                        </Text>
                      )}
                    </li>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Cancellations are accepted at least two hours before the show starts. No refunds will be issued for bankcard transactions, as we will provide you with credit valid for 30 days, which can be used to book online."
                      ) : (
                        <Text>
                          يتم قبول الإلغاء قبل ساعتين على الأقل من بدء العرض. لن
                          يتم إصدار أي مبالغ مستردة لمعاملات البطاقات المصرفية ،
                          حيث سنوفر لك رصيدًا صالحًا لمدة 30 يومًا ، والذي يمكن
                          استخدامه للحجز عبر الإنترنت.
                        </Text>
                      )}
                    </li>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Cinescape reserves the right to shift your seat in case a free seat is available next or in between your seats."
                      ) : (
                        <Text>
                          تحتفظ Cinescape بالحق في تغيير مقعدك في حالة توفر مقعد
                          مجاني بجوار أو بين مقاعدك.
                        </Text>
                      )}
                    </li>
                    <li>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Children of all ages need tickets for admission."
                      ) : (
                        <Text>
                          يحتاج الأطفال من جميع الأعمار إلى تذاكر للدخول.
                        </Text>
                      )}

                      <ol type="a">
                        <li>
                          {localStorage.getItem("language") !== "ar" ? (
                            "Cinescape reserves the right to shift your seat in case a free seat is available next or in between your seats."
                          ) : (
                            <Text>
                              تحتفظ سينسكيب بالحق في تغيير مقعدك في حالة توفر
                              مقعد مجاني بجوار أو بين مقاعدك.
                            </Text>
                          )}
                        </li>
                        <li>
                          {localStorage.getItem("language") !== "ar" ? (
                            "Children, younger than 3 years, are granted free admissions to all Indian movies (Only one child per guardian is allowed, as children may not occupy a seat)"
                          ) : (
                            <Text>
                              يُمنح الأطفال الذين تقل أعمارهم عن 3 سنوات دخولًا
                              مجانيًا إلى جميع الأفلام الهندية (يُسمح بطفل واحد
                              فقط لكل وصي ، حيث لا يجوز للأطفال شغل مقعد)
                            </Text>
                          )}
                        </li>
                      </ol>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* <div className="input-check">
                <input
                  type="checkbox"
                  name="agree"
                  id="agree"
                  onClick={() => this.agree()}
                />
                {localStorage.getItem("language") !== "ar" ? (
                  "I agree to the above rules"
                ) : (
                  <Text>أوافق على القواعد المذكورة أعلاه</Text>
                )}
              </div> */}
              {this.state.showNextButton ? (
                <div
                  className="proceed-container proceed-btn"
                  onClick={() => this.setSeats()}
                >
                  <button className="btnc btnc-primary">
                    {localStorage.getItem("language") !== "ar" ? (
                      "PROCEED"
                    ) : (
                      <Text>تقدم</Text>
                    )}
                  </button>
                </div>
              ) : (
                <div className="proceed-container proceed-btn">
                  <button className="btnc " disabled>
                    {localStorage.getItem("language") !== "ar" ? (
                      "PROCEED"
                    ) : (
                      <Text>تقدم</Text>
                    )}
                  </button>
                </div>
              )}
            </div>
            {/* <div className="mobile-border-bot"></div> */}
          </div>
        </div>

        <div>
          {/* <button onClick={this.openModal}>Open Modal</button> */}
          <Modal
            isOpen={this.state.isModalOpen}
            onRequestClose={this.closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            {/* <button onClick={this.closeModal} className="icon-close"> <i class="fas fa-times"></i></button> */}
            <div className="modal-bookings scroll-bar">
              <h1>   {localStorage.getItem("language") !== "ar" ? (
              "Bookings Found!"
            ) : (
              <Text>الحجوزات موجودة!</Text>
            )}</h1>
              <div class="timing-slot" id="timing-slot">
                {this.state.bookingsFound.map(function(item,i){
                  return(
                    <>
                     <div class="timing-slot-times time-slot-cinemasessions ">
                  <div class="img-cinemasession">
                    <img
                      src={item.poster}
                      alt={item.moviename}
                    />
                  </div>
                  <div class="movie-cinemasession">
                    <div class="boocking-section">
                      <div class="booking_text">
                      <span className="movie-seat-name">{item.moviename} </span>
                        <span class="age-highlight age_btn new-payment-rating">
                        {item.mcensor}
                        </span>
                      </div>

                      <div class="booking_text booking-date-new">
                        <p>{item.cinemaname} {item.showDate}</p>
                        <p>{item.experience} {item.numofseats} Tickets</p>
                      </div>
                    </div>
                  </div>
                </div>
                    </>
                  )
                })}
               

             
                
              </div>

              <div className="btn-goto">
                <a href="/myaccount?tab=bookings"> <button class="btnc btnc-ghost proceed-btn btn-booking">
                    {localStorage.getItem("language") !== "ar" ? (
              "Go to Bookings"
            ) : (
            <Text>اذهب إلى الحجوزات
          </Text>  )}
                  </button></a> 
                  <button onClick={() => {
                          this.setState({ doBooking: true }, () => {
                            // This callback is executed after the state update is done
                            this.setSeats();
                          });
                        }} class="btnc btnc-ghost proceed-btn btn-booking">
                {localStorage.getItem("language") !== "ar" ? (
              "Continue booking"
            ) : (
            <Text>استمر في الحجز</Text>  )}
                  </button>
                </div>
                
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
export default SeatLayout;
