import axios from "axios";
import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import ShowDetails from "../showdetails/ShowDetails.component";
import Modal from "react-modal";
import TrailerModal from "../trailer/trailer.component";
import { Link, Redirect } from "react-router-dom";
import Text from "react-text";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Loading } from "../header/loader.component";
import swal from "sweetalert";
import Login from "../../pages/login.component";
import Carousel from "react-multi-carousel";
import { useLocation } from 'react-router-dom';
import "react-multi-carousel/lib/styles.css";



let arabicmodal =
  localStorage.getItem("language") === "ar" ? "arabicmodal" : "";
let crossarabic =
  localStorage.getItem("language") === "ar" ? "crossarabic" : "";

class MovieSession extends Component {
  // constructor(props) {
  //   super(props);
  // this.
  state = {
    city: this.props.match.params.city,
    amcode: this.props.match.params.amcode,
    mname: this.props.match.params.moviename,
    lat: 0.0,
    lng: 0.0,
    date: "",
    lang: "ALL",
    format: "ALL",
    price: "ALL",
    selectedPrice: 0,
    time: "ALL",
    cinetype: "ALL",
    special: "ALL",
    hc: "ALL",
    output: {},
    lngs: [],
    daySessions: [],
    cinema: {},
    shows: [],
    days: [],
    seats: [],
    selectedDate: "",
    selectedDay: "",
    todaydate: "",
    showtime: "",
    showTrailer: false,
    category: "",
    seatType: "",
    ttypeCode: "",
    // category: "Bachelor",
    // seatType: "Premium",
    selectedCinema: 0,
    quantity: 1,
    selectedShowTime: "",
    movies: {},
    showTrailer: false,
    trailerUrl: "",
    isHovered: false,
    isHovered2: false,
    isHovered3: false,
    isHovered4: false,
    showProceed: false,
    areacode: "",
    seatCategory: [],
    seatTypes: [],
    seatType: "",
    experienceSessions: [],
    cinemaname: [],
    locations:[],
    seatCount: 0,
    login: false
  };
  // }

  componentDidMount() {
      


    let testIfJson = JSON.parse(localStorage.getItem("seatLayoutJson"));

    axios
      .post(
        Constants.api + "content/msessionsnew",
        {
          dated: this.state.todaydate,
          mid: this.state.amcode,
        },

        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
          },
        }
      )
      .then((res) => {
        console.log(res);
        let days = [];
        let movies = {};
        if (res.data.output != null) {
          console.log(res.data.output.days);

          Object.keys(res.data.output.days).forEach(function (key2) {
            console.log("showing dates...");
            days.push(res.data.output.days[key2]);
          });

          if (
            localStorage.getItem("seatLayoutJson") &&
            typeof testIfJson == "object"
          ) {
            console.log("date already set");
          } else {
            this.setState({
              selectedDay: days[0]["wdf"],
              selectedDate: days[0]["dt"],
            });
          }
          movies = res.data.output.movie;
          this.setState({
            days: days,
            movies: movies,
            daySessions: res.data.output.daySessions,
          });
          let cinemaname = [];
          let locations = [];

          res.data.output.daySessions.map(function (item, i) {
            cinemaname.push(i);
            locations.push(item.cinema.name);
          });
          this.setState({
            cinemaname,locations
          });
        } else swal(res.data.msg);
        // console.log(this.state.shows[0]["showTime"]);

        if (
          localStorage.getItem("seatLayoutJson") &&
          typeof testIfJson == "object"
        ) {
          let cinemaId = testIfJson.cinemaId;
          let sessionId = testIfJson.sessionId;
          let showtime = testIfJson.showtime;

          let mid = testIfJson.mid;
          let dated = testIfJson.dated;
          let selectedDate = testIfJson.dated;
          // this.setState({ selectedDate, sessionId })

          let datetobeclicked = "date" + dated;
          let cinematobeclicked = "cinema" + cinemaId;

          let timetobeclicked = "time" + sessionId;
          if (
            localStorage.getItem("experience"))
            this.setState('experience',localStorage.getItem("experience"))
          // Now the page has fully loaded, you can execute your code here
          this.handleClickById(
            "date",
            datetobeclicked,
            cinematobeclicked,
            timetobeclicked,
            cinemaId,
            sessionId,
            dated,
            mid,
            showtime,
            localStorage.getItem("experience")
          );

          this.scrolltoDiv("scrool");
        }
      });
    //    localStorage.removeItem('seatLayoutJson');
  }
  scrolltoDiv = (id) => {
    var element = document.getElementById(id);
    element.scrollIntoView();
  };
  clickProceed = () => {
    const moviesessionPath = window.location.pathname;

    // Store the URL path in local storage
    localStorage.setItem("moviesessionPath", moviesessionPath);
    if (localStorage.getItem("token") && localStorage.getItem("token") != "") {
      this.setState({
        seatLayout: true,
      });
    } else {
      console.log("seatLayoutJson");

      let seatLayoutJson = {
        quantity: this.state.quantity,
        areacode: this.state.areacode,
        seats: this.state.seats,
        cinemaId: this.state.cinemaId,
        sessionId: this.state.sessionId,
        mid: this.state.amcode,
        ttypeCode: this.state.ttypeCode,
        url: window.location.href,
      };
      console.log(seatLayoutJson);
      // Put the object into storage
      localStorage.setItem("seatLayoutJson", JSON.stringify(seatLayoutJson));
      this.setState({ login: true });
    }
  };
  increaseQuantity = () => {
    if (this.state.quantity < this.state.seatCount)
      this.setState({ quantity: this.state.quantity + 1 });
    var element = document.getElementById("quantitydiv");
    element.scrollIntoView();
  };
  decreaseQuantity = () => {
    if (this.state.quantity > 1)
      this.setState({ quantity: this.state.quantity - 1 });
  };
  
handledropdownchange = (event) => {
  // Here, selectedIndex will give you the index of the selected option
 
  // You can perform any additional actions you need based on the selected option and index
  // For example, you can call a function with the selected index:
  this.clearSelection(event.target.value);
};
  handleClickById = (
    type,
    elementId,
    cinema,
    time,
    cinemaId,
    sessionId,
    dated,
    amcode,
    showTime,
    experience
  ) => {
    if (type != "time") {
      // alert(time)
      let elementToClick = document.getElementById(elementId);

      if (elementToClick) {
        elementToClick.click();
      } else {
        console.error(`Element with id "${elementId}" not found.`);
      }
      if (type == "date")
        this.handleClickById(
          "cinema",
          cinema,
          cinema,
          time,
          cinemaId,
          sessionId,
          dated,
          amcode,
          showTime,
          experience
        );
      if (type == "cinema")
        this.handleClickById(
          "time",
          time,
          cinema,
          time,
          cinemaId,
          sessionId,
          dated,
          amcode,
          showTime,
          experience
        );
    } else {
      this.getSeatLayoutAfterLogin(
        cinemaId,
        sessionId,
        showTime,
        amcode,
        dated,
        experience
      );
    }
  };
  getSeatLayoutAfterLogin = (cinemaId, sessionId, showtime, mid, dated,experience) => {
    if (!localStorage.getItem("token") || localStorage.getItem("token") == "") {
      let seatLayoutJson = {
        url: window.location.href,
        cinemaId,
        sessionId,
        showtime,
        mid,
        dated,
      };
      console.log(seatLayoutJson);
      // Put the object into storage
      localStorage.setItem("seatLayoutJson", JSON.stringify(seatLayoutJson));

      this.setState({ login: true });
      return;
    }
    this.setState({
      seatCategory: [],
      showtime: "",
      seats: [],
      loading: false,
      seatCount: "",
      category: "",
      seatTypes: "",
      showProceed: false,
      seatType: "",
    });

    let token = localStorage.getItem("token");
    localStorage.setItem("sessionID", sessionId);
    this.setState({
      showtime: showtime,
      cinemaId: cinemaId,
      sessionId: sessionId,
      loading: true,
    });
    axios
      .post(
        Constants.api + "content/trans/seatlayout",
        {
          cinemaId: cinemaId,
          sessionId: sessionId,
          mid: mid,
          dated: dated,
        },

        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            Authorization: `Bearer ${token}`,
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.result && res.data.result == "success") {
          this.setState({
            seatCategory: res.data.output.seatTypes,
            showtime: showtime,
            seats: res.data.output.rows,
            loading: false,
            seatCount: res.data.output.seatCount,
          });
        } else {
          swal(res.data.msg);
          this.setState({
            showtime: "",
            loading: false,
          });
        }
        // document.getElementById("timing-slot").scrollIntoView()
      })
      .catch((error) => {
        localStorage.clear();
        this.setState({ login: true });
      });
  };
  clearSelection = (cinema) => {
    this.setState({
      seatCategory: [],
      showtime: "",
      seats: [],
      loading: false,
      seatCount: "",
      category: "",
      seatTypes: "",
      showProceed: false,
      selectedCinema: cinema,
    });
  };
  render() {
    window.myComponentInstance = this;

    const clearSelection = (cinema) => {
      this.setState({
        seatCategory: [],
        showtime: "",
        seats: [],
        loading: false,
        seatCount: "",
        category: "",
        seatTypes: "",
        showProceed: false,
        selectedCinema: cinema,
      });
    };
    const changecategory = (
      category,
      seatType,
      areacode = false,
      ttypeCode,
      selectedPrice
    ) => {
      // document.getElementById("seat-category-section").scrollIntoView()
      console.log(seatType);
      this.setState({
        selectedPrice: selectedPrice,
        quantity: 1,
      });
      localStorage.setItem("seatCategory", category);
      if (seatType.length != 0) {
        this.setState({
          category: category,
          seatTypes: seatType,
          showProceed: false,
        });
        if (seatType.length == 1)
          this.setState({
            seatType: seatType[0].seatType,
            showProceed: true,
            areacode: seatType[0].areacode,
            ttypeCode: seatType[0].ttypeCode,
            selectedPrice: seatType[0].priceInt,
          });
        else {
          this.setState({
            seatType: "",
            showProceed: false,
            areacode: "",
            ttypeCode: "",
            selectedPrice: 0,
          });
        }
      } else
        this.setState({
          category: category,
          seatType: seatType,
          showProceed: true,
          areacode: areacode,
          ttypeCode: ttypeCode,
        });
      scrolltoDiv("seat-type-before");

      scrollagain();
    };
    const scrollagain = () => {
      scrolltoDiv("seat-type-after");
    };
    const setSeatType = (seatType, areacode, ttypeCode, selectedPrice) => {
      this.setState({
        seatType: seatType,
        showProceed: true,
        areacode: areacode,
        ttypeCode: ttypeCode,
        selectedPrice,
      });

      scrolltoDiv("quantitydiv");
    };
    let scrolltoDiv = (id) => {
      var element = document.getElementById(id);
      element.scrollIntoView();
    };
    const handleHover = () => {
      this.setState((prevState) => ({
        isHovered: !prevState.isHovered,
      }));
    };
    const handleHover2 = () => {
      this.setState((prevState) => ({
        isHovered2: !prevState.isHovered2,
      }));
    };
    const handleHover3 = () => {
      this.setState((prevState) => ({
        isHovered3: !prevState.isHovered3,
      }));
    };

    const getSeatLayout = (cinemaId, sessionId, showtime, mid, dated,experience) => {
      localStorage.setItem("experience", experience);
      localStorage.setItem("bookType", 'BOOKING');

      scrolltoDiv("scrool");
      if (
        !localStorage.getItem("token") ||
        localStorage.getItem("token") == ""
      ) {
        let seatLayoutJson = {
          url: window.location.href,
          cinemaId,
          sessionId,
          showtime,
          mid,
          dated,
        };
        console.log(seatLayoutJson);
        // Put the object into storage
        localStorage.setItem("seatLayoutJson", JSON.stringify(seatLayoutJson));

        this.setState({ login: true });
        return;
      }
      this.setState({
        seatCategory: [],
        showtime: "",
        seats: [],
        loading: false,
        seatCount: "",
        category: "",
        seatTypes: "",
        showProceed: false,
        seatType: "",
      });

      let token = localStorage.getItem("token");
      localStorage.setItem("sessionID", sessionId);
      this.setState({
        showtime: showtime,
        cinemaId: cinemaId,
        sessionId: sessionId,
        loading: true,
      });
      axios
        .post(
          Constants.api + "content/trans/seatlayout",
          {
            cinemaId: cinemaId,
            sessionId: sessionId,
            mid: mid,
            dated: dated,
          },

          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              Authorization: `Bearer ${token}`,
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result && res.data.result == "success") {
            this.setState({
              seatCategory: res.data.output.seatTypes,
              showtime: showtime,
              seats: res.data.output.rows,
              loading: false,
              seatCount: res.data.output.seatCount,
            });
            if (res.data.output.seatTypes.length == 1) {
              changecategory(
                this.state.seatCategory[0].seatType,
                this.state.seatCategory[0].seatTypes,
                this.state.seatCategory[0].areacode,
                this.state.seatCategory[0].ttypeCode,
                this.state.seatCategory[0].priceInt
              );
            }
          } else {
            swal(res.data.msg);
            this.setState({
              showtime: "",
              loading: false,
            });
          }
          // document.getElementById("timing-slot").scrollIntoView()
        })
        .catch((error) => {
          localStorage.clear();
          this.setState({ login: true });
        });
    };
    const selectDate = (date, day) => {
      this.setState({
        seatCategory: [],
        showtime: "",
        seats: [],
        loading: false,
        seatCount: "",
        category: "",
        seatTypes: "",
        showProceed: false,
      });
      document.getElementById("movie-date-box").scrollIntoView();
      this.setState({ selectedDate: date, selectedDay: day, loading: true });

      try {
        axios
          .post(
            Constants.api + "content/msessionsnew",
            {
              dated: date,
              mid: this.state.amcode,
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              },
            }
          )
          .then((resp) => {
            console.log(resp);
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: false,
              });
              console.log(resp.data.output.daySessions);
              // let cinemas = [];

              //  cinemas.push(resp.data.output.daySessions);

              console.log(this.state.cinemas);

              // var element = document.getElementById("otherDateFilter");
              // element.classList.remove("active");

              this.setState({
                daySessions: resp.data.output.daySessions,

                loading: false,
              });
              console.log(this.state.shows);

              // Abhishek//
              let cinemaname = [];
              let locations = [];

              resp.data.output.daySessions.map(function (item, i) {
                cinemaname.push(i);
                locations.push(item.cinema.name);
              });
              this.setState({
                cinemaname,locations
              });
              // End Abhishek//


            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    let movieinfo = { ...this.state.movies };
    let director = { ...movieinfo.director };
    let cast = { ...movieinfo.cast };
    let mh = Math.floor(movieinfo.runTime / 60);
    let mm = movieinfo.runTime % 60;
    let seatCategory = this.state.seatCategory;
    const entries = Object.entries(cast);
    const renderCast = [];
    for (const [key, values] of entries) {
      let fullName = `${values.firstName} ${values.lastName}`;
      renderCast.push(fullName);
    }
    console.log(movieinfo);
    console.log(this.state.selectedDay);
    console.log(this.state.selectedDate);

    // dropdown menu
    const options = this.state.locations;
    let selectedCinema = this.state.selectedCinema;

    const defaultOption = options[selectedCinema];

    const keys = Object.keys(this.state.cinema);

    let selectedShowTime = this.state.sessionId;
    let selectedDate = this.state.selectedDate;

    const responsive5 = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: keys.length >= 8 ? 8 : 6,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: keys.length >= 8 ? 8 : 6,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };

    const days = this.state.days.map(function (item, i) {
      let active = selectedDate == item.dt ? "active" : "";

      return (
        <Tab>
          <div className="movie-date-box" id="movie-date-box">
            <div
              id={`date${item.dt}`}
              className={`movie-date ${active}`}
              onClick={() => selectDate(item.dt, item.wdf)}
            >
              <p>{item.wd}</p>
              <p>{item.d}</p>
            </div>
            <div className="border-right"></div>
          </div>
        </Tab>
      );
    });
    let category = this.state.category;
    let seatType = this.state.seatType;
    let amcode = this.state.amcode;
    return (
      <>
        <div className="banner-item movie-banner movie-banner-img">
          <div className="banner-item-img">
            {movieinfo.webimgbig && movieinfo.webimgbig.length > 0 ? (
              <img src={movieinfo.webimgbig} alt="" />
            ) : (
              <img
                src={require("../../assets/bean.jpg").default}
                alt={movieinfo.title}
              />
            )}
          </div>

          {/* desktop view */}
          <div className="movie-banner">
            <div className="movie-banner-bottom moviesession-desktop">
              <div className="banner-info">
                <div className="banner-info-upper">
                  <h3 className="title-info">{movieinfo.title}</h3>
                  <div
                    className="age-highlight title-info"
                    style={{
                      backgroundColor: movieinfo.ratingColor
                        ? movieinfo.ratingColor
                        : "",
                    }}
                  >
                    {movieinfo.rating}
                  </div>

                  <div className="movie-titles">
                    <span className="movie-title d-none-desktop">
                      {movieinfo.title}
                    </span>
                    <span
                      className="age-highlight d-none-desktop"
                      style={{ backgroundColor: movieinfo.ratingColor }}
                    >
                      {movieinfo.rating}
                    </span>
                  </div>

                  <div className="banner-info-lower-part banner-trailer">
                    {movieinfo.trailerUrl != "" ? (
                      <a
                        className="pointer-cursor"
                        onClick={() => {
                          this.setState({
                            showTrailer: true,
                            trailerUrl: movieinfo.trailerUrl,
                            moviename: movieinfo.title,
                          });
                        }}
                      >
                        <img
                          src={require("../../assets/play.png").default}
                          className="play-icon"
                        />
                        <p>
                          {" "}
                          {localStorage.getItem("language") !== "ar" ? (
                            "Watch Trailer"
                          ) : (
                            <Text>مشاهد الإعلان</Text>
                          )}
                        </p>
                      </a>
                    ) : (
                      ""
                    )}
                    {/* <a href={movieinfo.trailerUrl} target="_blank">
                    <img
                      src={require("../../assets/play.png").default}
                      className="play-icon"
                    />
                    <p>Watch Trailer</p>
                  </a> */}
                  </div>
                </div>
              </div>
              <div className="banner-info-lower justify-flex pad-60">
                <div className="banner-info-lower-part movie-info">
                  <div className="movie-info-box">
                    <p>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Language"
                      ) : (
                        <Text>اللغة</Text>
                      )}
                    </p>
                    <p>{movieinfo.language}</p>
                  </div>
                  <div className="movie-info-box">
                    <p>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Genre"
                      ) : (
                        <Text>النوع</Text>
                      )}
                    </p>
                    <p>{movieinfo.genre}</p>
                  </div>
                  <div className="movie-info-box">
                    <p>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Subtitle"
                      ) : (
                        <Text>الترجمة</Text>
                      )}
                    </p>

                    <p> {movieinfo.subTitle}</p>
                  </div>
                  <div className="movie-info-box">
                    <p>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Run Time"
                      ) : (
                        <Text>المدة</Text>
                      )}
                    </p>
                    <p>{movieinfo.runTimeStr}</p>
                  </div>
                  <div className="movie-info-box">
                    <p>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Director"
                      ) : (
                        <Text>المخرج</Text>
                      )}
                    </p>
                    <p>
                      {director.firstName} {director.lastName}
                    </p>
                  </div>
                  <div className="movie-info-box">
                    <p>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Cast"
                      ) : (
                        <Text>
                        بطولة
                        </Text>
                      )}
                    </p>
                    <p>{renderCast.join("  |  ")}</p>
                    {/* <p>Daniel Craig | Ralph Fiennes | Lea Seydoux | Rami Malek</p> */}
                  </div>
                </div>
                <div className="movie-info">
                  <div className="movie-info-box movie-inf0-synopsis">
                    <p>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "synopsis"
                      ) : (
                        <Text>القصة</Text>
                      )}
                    </p>
                    <p className="text-synopsis">{movieinfo.synopsis}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* desktop view */}

          {/* mobile view */}
          <div className="movie-banner-bottom moviesession-mobile">
            <div className="banner-moviession-title">
              <div className="banner-info">
                <div className="banner-info-upper">
                  <h3 className="title-info">{movieinfo.title}</h3>
                  <div
                    className="age-highlight title-info"
                    style={{
                      backgroundColor: movieinfo.ratingColor
                        ? movieinfo.ratingColor
                        : "",
                    }}
                  >
                    {movieinfo.rating}
                  </div>

                  <div className="movie-titles">
                    <span className="movie-title d-none-desktop">
                      {movieinfo.title}
                    </span>
                    <span
                      className="age-highlight d-none-desktop"
                      style={{ backgroundColor: movieinfo.ratingColor }}
                    >
                      {movieinfo.rating}
                    </span>
                  </div>

                  <div className="banner-info-lower-part banner-trailer">
                    {movieinfo.trailerUrl != "" ? (
                      <a
                        className="pointer-cursor"
                        onClick={() => {
                          this.setState({
                            showTrailer: true,
                            trailerUrl: movieinfo.trailerUrl,
                            moviename: movieinfo.title,
                          });
                        }}
                      >
                        <img
                          src={require("../../assets/play.png").default}
                          className="play-icon"
                        />
                        <p>
                          {" "}
                          {localStorage.getItem("language") !== "ar" ? (
                            "Watch Trailer"
                          ) : (
                            <Text>مشاهد الإعلان</Text>
                          )}
                        </p>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="banner-info-lower justify-flex pad-60">
              <div className="banner-info-lower-part movie-info">
                <div className="movie-info-box">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Language"
                    ) : (
                      <Text>اللغة</Text>
                    )}
                  </p>
                  <p>{movieinfo.language}</p>
                </div>

                <div className="sub-title">
                  <div className="movie-info-box movie-subtitle">
                    <p>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Subtitle"
                      ) : (
                        <Text>الترجمة</Text>
                      )}
                    </p>

                    <p> {movieinfo.subTitle}</p>
                  </div>
                </div>

                <div className="movie-info-box">
                  <p>
                    {" "}
                    {localStorage.getItem("language") !== "ar" ? (
                      "Genre"
                    ) : (
                      <Text>النوع</Text>
                    )}
                  </p>
                  <p>{movieinfo.genre}</p>
                </div>

                <div className="movie-info-box">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Director"
                    ) : (
                      <Text>المخرج</Text>
                    )}
                  </p>
                  <p>
                    {director.firstName} {director.lastName}
                  </p>
                </div>

                <div className="movie-info-box">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Cast"
                    ) : (
                      <Text>
                       بطولة
                      </Text>
                    )}
                  </p>
                  <p>{renderCast.join("  |  ")}</p>
                  {/* <p>Daniel Craig | Ralph Fiennes | Lea Seydoux | Rami Malek</p> */}
                </div>

                <div className="movie-info-box">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Run Time"
                    ) : (
                      <Text>المدة</Text>
                    )}
                  </p>
                  <p>{movieinfo.runTimeStr}</p>
                </div>
              </div>
              <div className="movie-info">
                <div className="movie-info-box">
                  <p>
                    {" "}
                    {localStorage.getItem("language") !== "ar" ? (
                      "synopsis"
                    ) : (
                      <Text>القصة</Text>
                    )}
                  </p>
                  <p className="text-synopsis">{movieinfo.synopsis}</p>
                </div>
              </div>
            </div>
          </div>
          {/* mobile view */}
        </div>
        <div className="home-content-container">
          <div className="date-time-section padbothvertical-40 date-and-time">
            <div className="main-head">
              <h3>
                {localStorage.getItem("language") !== "ar" ? (
                  "Select Date & Time"
                ) : (
                  <Text>حدد التاريخ والوقت</Text>
                )}
              </h3>
            </div>
            <div className="movie-dates">
              <Tabs>
                <TabList>
                  <div className="movie-date-tabs">
                    <div className="movie-week">{days}</div>
                    <div className="date-filter hidden">
                      <img src={require("../../assets/filter2.png").default} />
                      <div className="filter-buttons">
                        <button className="btnc btnc-ghost">
                          {" "}
                          {localStorage.getItem("language") !== "ar" ? (
                            "EXPERIENCE"
                          ) : (
                            <Text>خبرة</Text>
                          )}
                        </button>
                        <button className="btnc btnc-ghost">
                          {localStorage.getItem("language") !== "ar" ? (
                            "TIME"
                          ) : (
                            <Text>زمن</Text>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </TabList>

                {/* <TabPanel> */}
                <Tabs>
                  <TabList>
                    {/* <div className="cinema-hall-tabs">
                      {this.state.daySessions.map(function (item, i) {
                        return <li className={`react-tabs__tab ${(i == selectedCinema) ? "react-tabs__tab--selected" : ''}`} onClick={() => clearSelection(i)}>{item.cinema.name}</li>

                      })}
                    </div> */}

                    <div className="slider-carousel">
                      <Carousel
                        className="banner-slider"
                        swipeable={false}
                        draggable={false}
                        arrows={true}
                        showDots={false}
                        responsive={responsive5}
                        ssr={true} // means to render carousel on server-side.
                        infinite={false}
                        autoPlay={false}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={[]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                      >
                        {this.state.daySessions.map(function (item, i) {
                          return (
                            <div
                              id={`cinema${item.cinema.id}`}
                              className={`cinemacarousal ${
                                i == selectedCinema ||
                                item.cinema.id === selectedCinema
                                  ? "cinemacarousal--selected"
                                  : ""
                              }`}
                              onClick={() => clearSelection(i)}
                            >
                              {item.cinema.name}
                            </div>
                          );
                        })}
                      </Carousel>
                    </div>

                    {/* mobile-cinemas list */}
                    <div className="cinema-hall-mobile">
                      <p className="loc-tag">Location</p>
                      {/* <Dropdown
                        options={options}
                        onChange={this.handledropdownchange}
                        value={defaultOption}
                      /> */}
                   
                      <select class="Dropdown-control" onChange={this.handledropdownchange} value={selectedCinema}>
                      {options.map((option, index) => (
                        <option key={index} value={index}>
                          {option}
                        </option>
                      ))}
                    </select>
                    </div>
                  </TabList>

                  {this.state.daySessions.map(function (item, i) {
                    if (i == selectedCinema) {
                      return (
                        <>
                          <TabPanel>
                            <div className="timing-slot" id="timing-slot">
                              {/* <div className="timing-slot-head">
                              <h3>{item.cinema.name}</h3>
                            </div> */}{" "}
                              {item.experienceSessions.map(function (item2, i) {
                                return (
                                  <div className="timing-slot-times">
                                    {
                                      <div className="time-left">
                                        <img src={item2.experienceIcon} />
                                      </div>
                                    }
                                   
                                  
                                    <div className="time-right">
                                   
                                      {item2.shows.map((sws, k) => {
                                        let active =
                                          selectedShowTime == sws.sessionId
                                            ? "active"
                                            : "";
                                        return (
                                          <>
                                          
                                            <div
                                              id={`time${sws.sessionId}`}
                                              className={`time-box ${active} tooltip` }
                                              onClick={() =>
                                                getSeatLayout(
                                                  sws.cinemaId,
                                                  sws.sessionId,
                                                  sws.showTime,
                                                  amcode,
                                                  selectedDate,
                                                  item2.experience

                                                )
                                              }

                                            >
                                              <p>
                                                {sws.showTime}
                                                <span>{sws.format}</span>
                                              </p>
                                              <span class="tooltiptext"><p>
                                                {sws.showTime}
                                                <span>{sws.format}</span>
                                              </p></span>
                                              
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                    </div>
                                 
                                );
                              })}
                            </div>
                          </TabPanel>
                        </>
                      );
                    }
                    // else{
                    //   return <>
                    //   <TabPanel>
                    //     <div className="timing-slot" id="timing-slot">

                    //       <div className="timing-slot-head">
                    //         <h3>{item.cinema.name}</h3>
                    //       </div>
                    //       {" "}
                    //       {
                    //         item.experienceSessions.map(function (item2, i) {
                    //           return <div className="timing-slot-times">
                    //             {<div className="time-left"><img src={item2.experienceIcon} /></div>}
                    //             <div className="time-right">
                    //               {item2.shows.map((sws, k) => {
                    //                 let active = selectedShowTime == sws.showTime ? "active" : "";
                    //                 return (
                    //                   <>
                    //                     <div
                    //                       className={`time-box ${active}`}
                    //                       onClick={() =>
                    //                         getSeatLayout(
                    //                           sws.cinemaId,
                    //                           sws.sessionId,
                    //                           sws.showTime,
                    //                           amcode,
                    //                           selectedDate
                    //                         )
                    //                       }
                    //                     >
                    //                       {sws.showTime}
                    //                       <span>{sws.format}</span>
                    //                     </div>
                    //                   </>
                    //                 );
                    //               })}
                    //             </div>
                    //           </div>

                    //         })}
                    //     </div>
                    //   </TabPanel>

                    // </>
                    // }
                  })}
                </Tabs>
                {/* </TabPanel> */}
              </Tabs>
            </div>
          </div>
          <div id="scrool">
            {this.state.loading ? <Loading /> : ""}
            {this.state.seatCategory.length > 0 ? (
              <div className="seat-category-section" id="seat-category-section">
                <div className="main-head">
                  <h3>
                    {localStorage.getItem("language") !== "ar" ? (
                      seatCategory && seatCategory[0].seatTypes.length == 0 ? (
                        "Select Seat Type"
                      ) : (
                        "Select Seat Category"
                      )
                    ) : (
                      <Text>حدد فئة المقعد </Text>
                    )}
                  </h3>
                </div>

                <div className="seat-category-contain">
                  {this.state.seatCategory.map(function (item, i) {
                    let catClass = "";
                    if (category == item.seatType) catClass = "active";
                    return (
                      <>
                        <div
                          className={`seat-category-box ${catClass}`}
                          onClick={() =>
                            changecategory(
                              item.seatType,
                              item.seatTypes,
                              item.areacode,
                              item.ttypeCode,
                              item.priceInt
                            )
                          }
                          onMouseEnter={handleHover}
                          onMouseLeave={handleHover}
                        >
                          <img src={item.icon} />
                          <img src={item.iconActive} />

                          <p>{item.seatType}</p>
                          {item.seatTypes.length == 0 ? (
                            <>
                              {" "}
                              <p>{item.count} </p> <p>{item.price}</p>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <span id="seat-type-before"></span>
          {this.state.seatTypes.length > 0 ? (
            <div
              className="seat-type-section seat-category-section"
              id="seat-type"
            >
              <div className="main-head">
                <h3>
                  {localStorage.getItem("language") !== "ar" ? (
                    "Select Seat Type"
                  ) : (
                    <Text>حدد نوع المقعد</Text>
                  )}
                </h3>
              </div>

              <div className="seat-category-contain">
                {this.state.seatTypes.map(function (item, i) {
                  let catClass = "";
                  if (seatType == item.seatType) catClass = "active";

                  return (
                    <div
                      className={`seat-category-box ${catClass}`}
                      onClick={() =>
                        setSeatType(
                          item.seatType,
                          item.areacode,
                          item.ttypeCode,
                          item.priceInt
                        )
                      }
                      onMouseEnter={() => handleHover3}
                      onMouseLeave={() => handleHover3}
                    >
                      <img src={item.icon} />
                      <img src={item.iconActive} />
                      <p>{item.seatType}</p>
                      <p className="other-info">{item.count}</p>
                      <p className="other-info">{item.price}</p>
                    </div>
                  );
                })}
              </div>
              {/* <div className="seat-terms">
                  <h4>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Seat selection terms "
                    ) : (
                      <Text>شروط اختيار المقعد</Text>
                    )}
                  </h4>
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "By selecting these seats, I hereby confirm that the seats selected in each grouping are for members of the same household only, seats cannot be changed once seated, and must maintain a minimum two meters distancing from all other groups. I also confirm that I am vaccinated/recovered and have presented the IMMUNE APP or the KUWAIT MOBILE ID APP where my status is confirmed in green/yellow/orange (Age categories exempted from MOH vaccination plan -below the age of 12- are excluded). I accept that violating these rules will result in losing these tickets without a refund. I also confirm that I will abide by all Cinescape’s COVID – 19 rules and regulations set forth in this booking process and in cinemas, and that violating them will result in losing these tickets."
                    ) : (
                      <Text>
                        باختيار هذه المقاعد ، أؤكد بموجب هذا أن المقاعد المختارة
                        في كل مجموعة مخصصة لأفراد من نفس الأسرة فقط ، ولا يمكن
                        تغيير المقاعد بمجرد الجلوس ، ويجب الحفاظ على مسافة مترين
                        على الأقل من جميع المجموعات الأخرى. أؤكد أيضًا أنني
                        تلقيت التطعيم / تعافيت وقدمت تطبيق منيع أو تطبيق رقم
                        الهاتف المحمول في الكويت حيث تم تأكيد حالتي باللون
                        الأخضر / الأصفر / البرتقالي (الفئات العمرية المستثناة من
                        خطة التطعيم بوزارة الصحة - أقل من 12 عامًا - مستبعدة) .
                        أوافق على أن انتهاك هذه القواعد سيؤدي إلى فقدان هذه
                        التذاكر دون استرداد. أؤكد أيضًا أنني سألتزم بجميع قواعد
                        ولوائح كوفيد سينسكيب - 19 المنصوص عليها في عملية الحجز
                        هذه وفي دور السينما ، وأن انتهاكها سيؤدي إلى فقدان هذه
                        التذاكر.
                      </Text>
                    )}
                  </p>
                </div> */}
            </div>
          ) : (
            ""
          )}
          <span id="seat-type-after"></span>

          <span id="quantitydiv"></span>
          {this.state.showProceed ? (
            <div className="seat-quantity-section seat-category-section">
              <div className="main-head" id="seat-type">
                <h3>
                  {localStorage.getItem("language") !== "ar" ? (
                    "Select Ticket Quantity"
                  ) : (
                    <Text>حدد كمية التذكرة</Text>
                  )}
                </h3>
              </div>

              <div className="ticket-counter">
                <div className="ticket-button">
                  <div className="ticket-info">
                    <p>
                      {this.state.seatType}
                      &nbsp;
                      {localStorage.getItem("language") !== "ar" ? (
                        "Ticket"
                      ) : (
                        <Text>تذكرة</Text>
                      )}
                    </p>
                  </div>
                  <div className="counter-info">
                    <div className="counter-button">
                      <button onClick={() => this.decreaseQuantity()}>
                        {this.state.quantity > 1 ? (
                          <i class="fa fa-minus" aria-hidden="true"></i>
                        ) : (
                          ""
                        )}
                      </button>
                      <input
                        type="text"
                        name="ticket-count"
                        id="ticket-count"
                        placeholder={this.state.quantity}
                      />
                      <button onClick={() => this.increaseQuantity()}>
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>

                    <div className="kd-total">
                      <p>
                        {localStorage.getItem("language") !== "ar" ? (
                          " KWD"
                        ) : (
                          <Text>دينار كويتي</Text>
                        )}{" "}
                        {parseFloat(
                          (this.state.selectedPrice * this.state.quantity) / 100
                        ).toFixed(3)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="gift-card" style={{ display: "none" }}>
                  <p>
                    {" "}
                    {localStorage.getItem("language") !== "ar" ? (
                      "Have a"
                    ) : (
                      <Text>عند</Text>
                    )}
                    &nbsp;
                    <b>
                      <u>
                        {localStorage.getItem("language") !== "ar" ? (
                          "Bank Offers"
                        ) : (
                          <Text>عروض البنك</Text>
                        )}
                      </u>
                    </b>{" "}
                    <b>
                      <u>
                        {localStorage.getItem("language") !== "ar" ? (
                          "Gift Vouchers"
                        ) : (
                          <Text>قسائم الهدايا</Text>
                        )}
                      </u>
                    </b>
                    &nbsp;&nbsp;
                    {localStorage.getItem("language") !== "ar" ? (
                      "or"
                    ) : (
                      <Text>أو</Text>
                    )}
                    &nbsp;&nbsp;
                    <b>
                      <u>
                        {localStorage.getItem("language") !== "ar" ? (
                          "Gift Card?"
                        ) : (
                          <Text>كرت هدية?</Text>
                        )}
                      </u>
                    </b>
                  </p>
                  <div className="input-code-container">
                    <div className="input-code-box">
                      <input
                        type="text"
                        name="code"
                        id="code"
                        placeholder={
                          localStorage.getItem("language") !== "ar"
                            ? " Enter Gift Voucher Code"
                            : "أدخل رمز قسيمة الهدية"
                        }
                      />
                      <img
                        src={require("../../assets/barcode.png").default}
                        alt=""
                      />
                    </div>
                    <button className="btnc btnc-primary">
                      {localStorage.getItem("language") !== "ar" ? (
                        "Apply"
                      ) : (
                        <Text>تطبيق</Text>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="age-restriction">
                <div className="age-allowed">
                  <div
                    class="age-highlight"
                    style={{ backgroundColor: movieinfo.ratingColor }}
                  >
                    {movieinfo.rating}
                  </div>
                  {/* <img
                    src={require("../../assets/18plus.png").default}
                    alt=""
                  /> */}
                </div>
                <div className="age-info">
                  <p>{movieinfo.ratingDescription}</p>
                  <p className="tc-web">
                    {localStorage.getItem("language") !== "ar" ? (
                      "By clicking Proceed, I agree to the"
                    ) : (
                      <Text>بالنقر فوق متابعة ، أوافق على</Text>
                    )}
                    &nbsp;
                    <a href="/terms" target="_blank">
                      {localStorage.getItem("language") !== "ar" ? (
                        "terms & conditions."
                      ) : (
                        <Text>الأحكام والشروط</Text>
                      )}
                    </a>
                  </p>
                </div>
                <p className="tc-mob desktop-view-tnc">
                  {localStorage.getItem("language") !== "ar" ? (
                    "By clicking Proceed, I agree to the"
                  ) : (
                    <Text>بالنقر فوق متابعة ، أوافق على</Text>
                  )}
                  &nbsp;
                  <a href="/terms" target="_blank">
                    {localStorage.getItem("language") !== "ar" ? (
                      "terms & conditions."
                    ) : (
                      <Text>الأحكام والشروط</Text>
                    )}
                  </a>
                </p>


                <p className="tc-mob mobile-view-tnc">
                  {localStorage.getItem("language") !== "ar" ? (
                    "By clicking Proceed, I agree to the"
                  ) : (
                    <Text>بالنقر فوق متابعة ، أوافق على</Text>
                  )}
                <br/>
                  <a href="/terms" target="_blank">
                    {localStorage.getItem("language") !== "ar" ? (
                      "terms & conditions."
                    ) : (
                      <Text>الأحكام والشروط</Text>
                    )}
                  </a>
                </p>
                { localStorage.getItem("experience")=='4DX'?
           localStorage.getItem("language") !== "ar" ? (   <center> <img
                class="seat-quantity-section "
                style={{"width":"60%"}}
                        src={require("../../assets/4DXwarning.png").default}
                        alt=""
                      /></center>)
                      : <center> <img
                      class="seat-quantity-section"
                      style={{"width":"60%"}}
                              src={require("../../assets/4DXwarning_ar.png").default}
                              alt=""
                            /></center>:''}


                <div className="proceed-container proceed-moviesession">
                  {this.state.showProceed && this.state.quantity > 0 ? (
                    <a onClick={this.clickProceed}>
                      <button className="btnc btnc-primary">
                        {localStorage.getItem("language") !== "ar" ? (
                          "PROCEED"
                        ) : (
                          <Text>تقدم</Text>
                        )}
                      </button>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div className="mobile-border-bot"></div> */}
            </div>
          ) : (
            ""
          )}
        </div>
        {this.state.seatLayout ? (
          <Redirect
            to={{
              pathname: "/seatlayout",

              state: {
                quantity: this.state.quantity,
                areacode: this.state.areacode,
                seats: this.state.seats,
                cinemaId: this.state.cinemaId,
                sessionId: this.state.sessionId,
                mid: this.state.amcode,
                ttypeCode: this.state.ttypeCode,
                seatCategory: category
              },
            }}
          />
        ) : (
          ""
        )}
        {this.state.showTrailer ? (
          <>
            <Modal
              isOpen={this.state.showTrailer}
              contentLabel="Example Modal"
              portalClassName="trai-modal"
            >
              {" "}
              <button
                className="trailer-cross trailer-movie trailer-movie-session"
                onClick={() => this.setState({ showTrailer: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              <TrailerModal
                trailerUrl={this.state.trailerUrl}
                name={this.state.moviename}
              />
            </Modal>
          </>
        ) : (
          ""
        )}
        <>
          <Modal
            isOpen={this.state.login}
            contentLabel="Example Modal"
            portalClassName={`trai-modal modal-name modal-login ${arabicmodal}`}
          >
            {" "}
            <button
              className={`trailer-cross modal-cross ${crossarabic}`}
              onClick={() => this.setState({ login: false })}
            >
              <i class="fas fa-times"></i>
            </button>
            <Login />
          </Modal>
        </>
      </>
    );
  }
}
export default MovieSession;
