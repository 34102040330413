import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "react-modal";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";

import Swal from "sweetalert2";
import moment from "moment";
import Text from "react-text";
import { Loading } from "../header/loader.component";
import { PaymentInputsContainer } from "react-payment-inputs";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { Link } from "react-router-dom";
class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tckDetailsOutput: {},
      selectSeat: [],
      tab: [],
      cinemaId: this.props.match.params.ccode,
      showcart: true,
      modalType: "",
      modalItem: {},
      selectedFlavor: "",
      selectedCombo: [],
      quantity: 1,
      calculatedTotal: 0,
      concessionFoods: [],
      time: {},
      //seconds: 300,
      showcreditcard: false,
      seconds: parseInt(localStorage.getItem('timeout')) * 60,
      expirationMonth: "",
      expirationYear: "",
      expiryDate: "",
      cardNumber: "",
      cvNumber: "",
      cardholderName:"",
      orgId: "",
      jwtToken: "",
      deviceSessionId: "",
      merchantId: "",
      selectedpaymentfirst: "",
      selectedpaymenttwo: "",
      paymodes: [],
      selectedbankoffer: 0,
      bankDiscount: 0,
      selectedoffer: "",
      selectedwallet: "",
      selectedcard: "",
      bankofferapplied: false,
      knetallowed: true,
      giftcardapplied: false,
      walletapplied: false,
      booktype: "BOOKING",
      bankOfferCard: "",
      giftOfferCard: "",
      giftCard: "",
      show: true,
      remainingAmount: 0,
      walletAmount: 0,
      showCardImg: false,
      payDone: false,
      paidFull: false,
      giftcardid: '',
      oncetimer: true,
      showProceed:true
    };

    this.timer = 0;
    this.unblock = null;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  // Function to trigger the click on the last accordion item
   triggerClickById = () => {
    const accordionItem = document.getElementById("accordion__heading-raa-3");
    if (accordionItem) {
      accordionItem.click(); // Simulate the click event on the accordion item
      this.setState({showProceed:false})
    }
  };

  clubPayment = () => {
    if (this.state.paidFull)
      window.location =
        "/bookingconfirm?result=success&bookingid=" +
        localStorage.getItem("bookingid") +
        "&type=BOOKING";
    this.setState({ loading: true });
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.api + "clubcard/clubPayment",
        {
          bookingid: localStorage.getItem("bookingid"),
          booktype: "BOOKING",
          transid: localStorage.getItem("transid"),
          upcoming: true,
          userid: localStorage.getItem("userid"),
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.result == "success") {
          if (res.data.output.PAID == "YES") {
            this.setState({ loading: false });
            window.location = "/bookingconfirm";
          }
          else
          this.setState({ loading: false,showProceed:false });

          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  };
  knetPayment = () => {
    let token = localStorage.getItem("token");
    this.setState({ loading: true });
    axios
      .post(
        Constants.api + "payment/knet/hmac",
        {
          bookingid: localStorage.getItem("bookingid"),
          booktype: "BOOKING",
          transid: localStorage.getItem("transid"),
          userid: localStorage.getItem("userid"),
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        console.log(res);
        if (res.data.result == "success") {
          window.location = res.data.output.callingUrl;
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  };
  cybersourcePayment = () => {
    let token = localStorage.getItem("token");

    this.setState({ loading: true });
    axios
      .post(
        Constants.api + "payment/cybersource/initiate",
        {
          bookingid: localStorage.getItem("bookingid"),
          userid: localStorage.getItem("userid"),
          transid: localStorage.getItem("transid"),
          booktype: "BOOKING",
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: true });
        console.log(res);
        if (res.data.result == "success") {
          this.setState({
            jwtToken: res.data.output.jwtToken,
            deviceSessionId: res.data.output.deviceSessionId,
            merchantId: res.data.output.merchantId,
            orgId: res.data.output.orgId,
          });
          const script = document.createElement("script");
          script.src =
            "https://h.online-metrix.net/fp/tags.js?org_id=" +
            this.state.orgId +
            "&session_id=" +
            this.state.merchantId +
            this.state.deviceSessionId;
          script.async = false;
          document.body.appendChild(script);
          window.Cardinal.configure({
            logging: {
              debug: "verbose",
            },
          });
          window.Cardinal.setup("init", {
            jwt: res.data.output.jwtToken,
          });
          let session_Id = "";
          window.Cardinal.on(
            "payments.setupComplete",
            function (setupCompleteData) {
              console.log("setup complete");
              console.log(setupCompleteData.sessionId);
              session_Id = setupCompleteData.sessionId;
            }
          );
          //  await sleep(10000); //wait 5 seconds
          setTimeout(
            function () {
              this.payerauth(session_Id);
            }.bind(this),
            10000
          );
          // this.payerauth(session_Id);
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  };
  payerauth = (
    session_Id,
    jwt,
    cardNumber,
    cvNumber,
    expirationMonth,
    expirationYear
  ) => {
    this.setState({ loading: true });
    // alert(session_Id);
    let token = localStorage.getItem("token");

    axios
      .post(
        Constants.api + "payment/cybersource/payerauth",
        {
          bookingid: localStorage.getItem("bookingid"),
          userid: localStorage.getItem("userid"),
          transid: localStorage.getItem("transid"),
          cardNumber: this.state.cardNumber.replaceAll(" ", ""),
          cvNumber: this.state.cvNumber,
          expirationMonth: this.state.expiryDate
            .split("/")[0]
            .replaceAll(" ", ""),
          expirationYear: this.state.expiryDate
            .split("/")[1]
            .replaceAll(" ", ""),
          referenceId: session_Id,
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        console.log(res);
        if (res.data.result == "success") {
          if (res.data.output.redirect == "1") {
            window.location = res.data.output.responseURL;
          } else {
            let jwt = this.state.jwtToken;
            let cardNumber = this.state.cardNumber.replaceAll(" ", "");
            let cvNumber = this.state.cvNumber;
            let expirationMonth = this.state.expiryDate
              .split("/")[0]
              .replaceAll(" ", "");
            let expirationYear = this.state.expiryDate
              .split("/")[1]
              .replaceAll(" ", "");
            let sessionid = this.state.deviceSessionId;
            this.cardinalCall(
              res.data.output,
              sessionid,
              jwt,
              cardNumber,
              cvNumber,
              expirationMonth,
              expirationYear
            );
          }
        } else {
          swal(res.data.msg);
        }
      });
  };
  cardinalCall = (data, sessionid, jwtToken, cn, cv, em, ey) => {
    let token = localStorage.getItem("token");

    this.setState({ loading: true });
    let xid = data.xid;
    console.log(xid);
    let jwt = jwtToken;
    const session_Id = sessionid;
    const cardNumber = cn;
    const cvNumber = cv;
    const expirationMonth = em;
    const expirationYear = ey;
    console.log("cardinal call");
    window.Cardinal.continue(
      "cca",
      {
        AcsUrl: data.acsURL,
        Payload: data.pares,
      },
      {
        OrderDetails: {
          TransactionId: data.authTransId,
        },
      }
    );
    window.Cardinal.on("payments.validated", function (data, jwt) {
      console.log(data);
      console.log(jwt);
      axios
        .post(
          Constants.api + "payment/cybersource/payerresponse",
          {
            bookingid: localStorage.getItem("bookingid"),
            cardNumber: cardNumber,
            cvNumber: cvNumber,
            expirationMonth: expirationMonth,
            expirationYear: expirationYear,
            payerAuth: jwt,
            sessionId: session_Id,
            xid: xid == null ? "" : xid,
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            window.location =
              "/bookingconfirm?result=success&bookingid=" +
              localStorage.getItem("bookingid") +
              "&type=BOOKING";
          } else {
            // this.setState({alert:res.data.msg});
            this.showConfirmationDialog(res.data.msg);
            window.location =
              "/bookingconfirm?result=fail&bookingid=" +
              localStorage.getItem("bookingid") +
              "&type=BOOKING";


          }
        });
    });
  };
  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.canceltrans();
    });
    localStorage.removeItem("seatLayoutJson");
    let token = localStorage.getItem("token");

    const tckDetailsRequest = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        // const resp =
        await axios
          .post(
            Constants.api + "content/trans/tcksummary/",
            {
              bookingId: localStorage.getItem("bookingid"),
              transid: localStorage.getItem("transid"),
              userId: localStorage.getItem("userid"),
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: false
              });
              let tckDetailsArray = [];
              localStorage.setItem("bookingid", resp.data.output.bookingId);
              this.setState({
                tckDetailsOutput: resp.data.output,
                concessionFoods: resp.data.output.concessionFoods,
                selectSeat: resp.data.output.seatsArr,
              });
              this.setState({
                totalPrice: resp.data.output.totalTicketPrice,
              });
            } else {
              swal(resp.data.msg);
              this.setState({
                loading: false
              });
            }
          });
      } catch (err) {
        console.error(err);
      }
    };
    tckDetailsRequest();

    const getpaymode = async () => {
      let token = localStorage.getItem("token");

      try {
        // const resp =
        await axios
          .post(
            Constants.api + "content/trans/paymodes/",
            {
              bookingId: localStorage.getItem("bookingid"),
              transid: localStorage.getItem("transid"),
              userId: localStorage.getItem("userid"),
            },

            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: false
              });
              console.log(resp.data.output);
              this.setState({
                paymodes: resp.data.output.payMode,
              });
            } else {
              this.showConfirmationDialog("Payment Declined")
              // window.location = localStorage.getItem("moviesessionPath");
              // swal(resp.data.msg);
              this.setState({
                loading: false
              });
            }
          });
      } catch (err) {
        console.error(err);
      }
    };
    getpaymode();
    //this.startTimer();

    if (localStorage.getItem('onlyFood'))
      console.log(localStorage.getItem('onlyFood'))
    else
      this.startTimer();
  }
  showConfirmationDialog = (msg) => {
    Swal.fire({
      title: '',
      text: msg,
      icon: 'info',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked the "Yes" button, you can perform the action here
        window.location = localStorage.getItem("moviesessionPath");
      } else {
        // User clicked the "No" button or closed the dialog

      }
    });
  }
  startTimer = () => {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    // Check if we're at zero.
    if (seconds == 60) {
      if (this.state.oncetimer) {


        Swal.fire({
          title: '',
          text: 'Give more time',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.isConfirmed) {
            // User clicked the "Yes" button, you can perform the action here
            this.setState({ seconds: seconds + 60, oncetimer: false });

            this.startTimer();
          } else {
            // this.canceltrans();
            // User clicked the "No" button or closed the dialog

          }
        });
      }
      else this.canceltrans();
      // window.location = "/";

      //clearInterval(this.timer);
    }
    if (this.state.seconds == 0) {
      Swal.fire({
        title: '',
        text: 'Timeout please try again later',
        icon: 'info',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.isConfirmed) {
          // User clicked the "Yes" button, you can perform the action here
          this.canceltrans();
        } else {
          this.canceltrans();
          // User clicked the "No" button or closed the dialog

        }
      });
    }
  };
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  canceltransPopup = () => {
    Swal.fire({
       title: '',
       text: 'Are you sure you want to cancel transaction?',
       icon: 'info',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes',
    }).then((result) => {
       if (result.isConfirmed) {
          this.canceltrans();
       } else {
         

       }
    });
  }


  canceltrans = () => {
    let token = localStorage.getItem("token");

    try {
      // const resp =
      axios
        .post(
          Constants.api + "content/trans/cancel/",
          {
            bookingId: localStorage.getItem("bookingid"),
            transid: localStorage.getItem("transid"),
            userId: localStorage.getItem("userid"),
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((resp) => {
          //  casetype array
          if (resp.data.result === "success") {
            //window.location = localStorage.getItem("moviesessionPath");
            if (localStorage.getItem('onlyFood')) {
              window.location = "/";
            } else {
                window.location = localStorage.getItem("moviesessionPath");
            }

          } else {
            swal(resp.data.msg);
            this.setState({
              loading: false
            });
          }
        });
    } catch (err) {
      console.error(err);
    }
  };
  render() {
    let proceed = () => {
      this.setState({ loading: true });
      if (this.state.selectedpayment != "") {
        if (this.state.selectedpayment == "Wallet") paywithWallet();
        if (this.state.selectedpayment == "KNET" || this.state.selectedpayment=="كي نت" ) this.knetPayment();
        if (this.state.selectedpayment == "Credit Card" || this.state.selectedpayment == 'بطاقة ائتمانية')
          this.cybersourcePayment();
        if (this.state.selectedpayment == "Gift Card")
          giftCardPay();
      } else swal("", "Please select a Payment Mode first", "info");
    };
    let giftCardPay = () => {
      let token = localStorage.getItem("token");
      if (this.state.giftOfferCard != "") {
        this.setState({ loading: true });
        axios
          .post(
            Constants.api + "giftcard/apply",
            {
              bookingid: localStorage.getItem("bookingid"),
              booktype: "BOOKING",
              cardNumber: this.state.giftOfferCard.replaceAll(" ", ""),
              offerId: this.state.giftcardid,
              payFull: true,
              transid: localStorage.getItem("transid"),
              userid: localStorage.getItem("userid"),
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);

            if (res.data.result == "success") {
              this.setState({ giftcardapplied: true, loading: false });
              if (res.data.output.PAID == "YES") {
                this.setState({ payDone: true, paidFull: true, selectedpayment: "Gift Card", remainingAmount: null });
                window.location =
                  "/bookingconfirm?result=success&bookingid=" +
                  localStorage.getItem("bookingid") +
                  "&type=BOOKING";
              }
              else
              this.setState({ loading: false,showProceed:false });
            } else {
              this.setState({ loading: false });
              // this.setState({alert:res.data.msg});
              swal(res.data.msg);
              window.location =
                "/bookingconfirm?result=fail&bookingid=" +
                localStorage.getItem("bookingid") +
                "&type=BOOKING";
            }
          });
      } else
        this.setState({
          showgifterror: true,
        });
      // window.location =
      //   "/bookingconfirm?result=success&bookingid=" +
      //   localStorage.getItem("bookingid") +
      //   "&type=BOOKING";
    }
    let canceltrans = () => {
      let token = localStorage.getItem("token");

      try {
        // const resp =
        axios
          .post(
            Constants.api + "content/trans/cancel/",
            {
              bookingId: localStorage.getItem("bookingid"),
              transid: localStorage.getItem("transid"),
              userId: localStorage.getItem("userid"),
            },

            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              window.location = "/";
            } else {
              swal(resp.data.msg);
              this.setState({
                loading: false
              });
            }
          });
      } catch (err) {
        console.error(err);
      }
    };
    let cardNumberChange = (event) => {

      if (!bankofferapplied)
        this.setState({
          cardNumber: event.target.value,
        },validateInputs);
    };
    let cvNumberChange = (event) => {
      this.setState({ cvNumber: event.target.value },validateInputs);
    };
    let cardNameChange = (event) => {
      this.setState({ cardholderName: event.target.value },validateInputs);
    };

    let expirationDateChange = (event) => {
      this.setState({ expiryDate: event.target.value },validateInputs);
    };

    let validateInputs = () => {
      const { cardNumber, expiryDate, cvNumber, cardholderName } = this.state;
    
      // Check if all inputs have a length greater than 0
      const allFilled =
        cardNumber?.length > 6 &&
        expiryDate?.length > 3 &&
        cvNumber?.length > 2 &&
        cardholderName?.length > 2;
    
      this.setState({ showProceed: allFilled });
    };
    let tckDetailsOutput = this.state.tckDetailsOutput;
    // let divStyle = {
    //   backgroundImage:
    //     "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%),  url(" +
    //     tckDetailsOutput.posterhori +
    //     ")",
    //   backgroundSize: "cover",
    // };

    let imageUrl = localStorage.getItem("onlyFood")
      ? `${process.env.PUBLIC_URL}/onlyfood.png`
      : tckDetailsOutput.posterhori;

    let divStyle = {
      backgroundImage:
        "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%),  url(" +
        imageUrl +
        ")",
      backgroundSize: "cover",
    };


    let openModal = (item, foodType) => {
      if (foodType == "Flavour" || foodType == "combo")
        this.setState({
          showModalFood: true,
          modalItem: item,
        });
      if (foodType == "Individual")
        this.addConcessionFoods(
          "Individual",
          item.id,
          item.description,
          item.descriptionAlt,
          item.headOfficeItemCode,
          item.itemPrice,
          item.itemType,
          item.parentId,
          item.priceInCents,
          1
        );
    };
    let selectedbankoffer = this.state.selectedbankoffer;
    let selectedoffer = this.state.selectedoffer;
    let selectedwallet = this.state.selectedwallet;
    let selectedcard = this.state.selectedcard;
    let cardNumber = this.state.cardNumber;
    let cvNumber = this.state.cvNumber;
    let expiryDate = this.state.expiryDate;
    let show = this.state.show;
    let remainingAmount = this.state.remainingAmount;
    let knetallowed = this.state.knetallowed;
    let selectBankOffer = (e) => {
      console.log(e.target.value);
      this.setState({ selectedbankoffer: e.target.value });
    };
    let bankOfferCardChange = (o) => {
      this.setState({
        bankOfferCard: o.target.value,
      });
    };
    let giftOfferCardChange = (o) => {
      this.setState({
        giftOfferCard: o.target.value,
      });
    };
    let selectOffer = (o) => {
      this.setState({ selectedoffer: o });
    };
    let selectWallet = (o, walletAmount) => {
      this.setState({ selectedwallet: o, walletAmount });
    };
    let selectCard = (show, o) => {
    
      if (show) {
        this.setState({
          selectedcard: o,
          selectedpayment: o,
         // showProceed:false
        });
        if(o =='KNET' || o=="كي نت")
          this.setState({
            showProceed:true
          });
          else
          this.setState({
            showProceed:false
          });
      }
    };
    let selectflavor = (item, price) => {
      this.setState({
        selectedFlavor: item,
        priceInCents: price,
      });
    };
    let selectCombo = (item, index) => {
      let selectedCombo = this.state.selectedCombo;
      selectedCombo[index] = item;
      this.setState({
        selectedCombo,
      });
      console.log("selectedCombo");
      console.log(selectedCombo);
    };
    let increasequantity = () => {
      let q = this.state.quantity + 1;
      this.setState({ quantity: q });
    };
    let decreasequantity = () => {
      if (this.state.quantity > 1) {
        let q = this.state.quantity - 1;
        this.setState({ quantity: q });
      }
    };
    let increaseFoodQuantity = (i) => {
      let concessionFoods = this.state.concessionFoods;
      concessionFoods[i].quantity = parseInt(concessionFoods[i].quantity + 1);
      this.setState({ concessionFoods });
    };
    let decreaseFoodQuantity = (i) => {
      let concessionFoods = this.state.concessionFoods;
      if (concessionFoods[i].quantity > 1)
        concessionFoods[i].quantity = parseInt(concessionFoods[i].quantity - 1);
      else concessionFoods.splice(i, 1);
      this.setState({ concessionFoods });
    };
    let removeFood = (i) => {
      let concessionFoods = this.state.concessionFoods;
      concessionFoods.splice(i, 1);
      this.setState({ concessionFoods });
    };
    let applyBankOffer = () => {
      if (this.state.bankOfferCard != "") {
        let token = localStorage.getItem("token");
        this.setState({ loading: true });
        axios
          .post(
            Constants.api + "bankoffer/apply",
            {
              bookingId: localStorage.getItem("bookingid"),
              cardNo: this.state.bankOfferCard.replaceAll(" ", ""),
              offerId: this.state.selectedbankoffer,
              transid: localStorage.getItem("transid"),
              userId: localStorage.getItem("userid"),
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              let discount_amt = "";
              let payInfo = res.data.output.payInfo;
              let discountItem = payInfo.find(
                (item) => item.key === "Discount"
              );
              discount_amt = discountItem ? discountItem.amt : null;

              this.setState({
                bankofferapplied: true,
                knetallowed: false,
                cardNumber: this.state.bankOfferCard,
                loading: false,
                remainingAmount: res.data.output.amount,
                bankDiscount: discount_amt,
                payDone: true
              });

              this.triggerClickById("GATEWAY");
              if(localStorage.language !='ar')
                this.setState({selectedcard:'Credit Card',selectedpayment:'Credit Card' })
              else
              this.setState({selectedcard: 'بطاقة ائتمانية',selectedpayment:'بطاقة ائتمانية'})
         
            } else {
              this.setState({ loading: false });
              // this.setState({alert:res.data.msg});
              swal(res.data.msg);
            }
          });
      } else
        this.setState({
          showbankerror: true,
        });
    };

    let removeBankOffer = () => {
      let token = localStorage.getItem("token");

      this.setState({ loading: true });
      axios
        .post(
          Constants.api + "bankoffer/remove",
          {
            bookingId: localStorage.getItem("bookingid"),
            cardNo: this.state.bankOfferCard,
            offerId: this.state.selectedbankoffer,
            transid: localStorage.getItem("transid"),
            userId: localStorage.getItem("userid"),
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              Authorization: `Bearer ${token}`,
              payDone: false

            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            this.setState({
              bankofferapplied: false, loading: false, bankOfferCard: '', knetallowed: true,remainingAmount: res.data.output.amount,
              cardNumber:"",selectedbankoffer:0
            });
          } else {
            this.setState({ loading: false });
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    };
    let applyGiftcard = (id) => {
      let token = localStorage.getItem("token");
      if (this.state.giftOfferCard != "") {
        this.setState({ loading: true });
        axios
          .post(
            Constants.api + "giftcard/apply",
            {
              bookingid: localStorage.getItem("bookingid"),
              booktype: "BOOKING",
              cardNumber: this.state.giftOfferCard.replaceAll(" ", ""),
              offerId: id,
              payFull: false,
              transid: localStorage.getItem("transid"),
              userid: localStorage.getItem("userid"),
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);

            if (res.data.result == "success") {
              this.setState({ giftcardapplied: true, loading: false });
              if (res.data.output.PAID == "YES") {
                this.setState({ payDone: true, paidFull: true, selectedpayment: "Gift Card", giftcardid: id, remainingAmount: null });


                // window.location =
                //   "/bookingconfirm?result=success&bookingid=" +
                //   localStorage.getItem("bookingid") +
                //   "&type=BOOKING";
              }
            } else {
              this.setState({ loading: false });
              // this.setState({alert:res.data.msg});
              swal(res.data.msg);
            }
          });
      } else
        this.setState({
          showgifterror: true,
        });
    };

    let removeGiftcard = (id) => {
      let token = localStorage.getItem("token");

      if (this.state.paidFull == true) {
        this.setState({ giftcardapplied: false });
        return;
      }
      this.setState({ loading: true });

      axios
        .post(
          Constants.api + "giftcard/remove",
          {
            bookingid: localStorage.getItem("bookingid"),
            cardNumber: this.state.giftOfferCard,
            offerId: id,
            transid: localStorage.getItem("transid"),
            userid: localStorage.getItem("userid"),
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            this.setState({ giftcardapplied: false, loading: false, payDone: true });
          } else {
            this.setState({ loading: false });
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    };
    let applyWallet = (id, walletAmount) => {
      let token = localStorage.getItem("token");

      this.setState({ loading: true, selectedwallet: id, walletAmount });
      axios
        .post(
          Constants.api + "clubcard/apply",
          {
            bookingid: localStorage.getItem("bookingid"),
            booktype: this.state.booktype,
            payFull: false,
            transid: localStorage.getItem("transid"),
            userid: localStorage.getItem("userid"),
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {

          console.log(res);
          if (res.data.result == "success") {
            this.setState({
              walletapplied: true,
              remainingAmount: res.data.output.amount,
              selectedpayment: "Wallet",
              loading: false,
              showProceed:false,

              payDone: true
            });
            if (res.data.output.PAID == "YES") {
              if (res.data.output.CAN_PAY == "YES") {
                //  paywithWallet();
                this.setState({
                  paidFull: true,
                  showProceed:true,

                });

              }
              else{
                //  paywithWallet();
                this.setState({
            
                  showProceed:true,

                });

              }
          
            } else
              {
                this.setState({
                walletapplied: true,
                remainingAmount: res.data.output.amount,
                loading: false,
                showProceed:false,
                payDone: false
              });
            }
          } else {
            this.setState({ loading: false });
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    };
    let removeWallet = (id) => {
    // alert(id)
      let token = localStorage.getItem("token");


      if (this.state.paidFull == true) {
        this.setState({ walletapplied: false });
        return;
      }
      this.setState({ loading: true });

      axios
        .post(
          Constants.api + "clubcard/remove",
          {
            bookingid: localStorage.getItem("bookingid"),
            booktype: this.state.booktype,
            payFull: false,
            transid: localStorage.getItem("transid"),
            userid: localStorage.getItem("userid"),
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            this.setState({
              walletapplied: false,
              loading: false,
              remainingAmount: res.data.output.amount,
              selectedwallet: "",
              payDone: false
            });
          } else {
            this.setState({ loading: false });
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    };
    let paywithWallet = () => {
      let token = localStorage.getItem("token");


      axios
        .post(
          Constants.api + "clubcard/apply",
          {
            bookingid: localStorage.getItem("bookingid"),
            booktype: this.state.booktype,
            payFull: true,
            transid: localStorage.getItem("transid"),
            userid: localStorage.getItem("userid"),
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          console.log(res);
          if (res.data.result == "success") {
            this.setState({ loading: false })
            if (res.data.output.PAID == "YES") {
              this.setState({ payDone: true, loading: false })
              window.location = "/bookingconfirm?result=success";
            }
            else {
              window.location = "/bookingconfirm?result=fail";
            }
          } else {

            this.setState({ loading: false });
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);


          }
        });
    };
    let selectedFlavor = this.state.selectedFlavor;
    let selectedCombo = this.state.selectedCombo;
    let bankofferapplied = this.state.bankofferapplied;
    let walletapplied = this.state.walletapplied;
    let paidFull = this.state.paidFull;

    let bankOfferCard = this.state.bankOfferCard;
    let giftOfferCard = this.state.giftOfferCard;

    let giftcardapplied = this.state.giftcardapplied;
    let giftCard = this.state.giftCard;
    let fprice = 0;
    let url =
      "https://h.online-metrix.net/fp/tags?org_id=" +
      this.state.orgId +
      "&session_id=" +
      this.state.merchantId +
      this.state.deviceSessionId;
    let isDisabled = false; // Set the disabled state here based on your conditions

    return (
      <>
        {this.state.loading ? <Loading /> : ""}
        <div class="cinescape_booking_section cinescape-booking-details newpayment-details">
          <div class="cinescape_section" style={divStyle}>
            <div class="cinescape_booking ">
              {localStorage.getItem("onlyFood") ? (
                ""
              ) : (
              <div class="cinescape_booking_id home-content-container">
                <>
                <div class="cinescape_img">
                  <div class="booking_img">
                    <img
                      src={
                        tckDetailsOutput.poster ? tckDetailsOutput.poster : ""
                      }
                      alt="img"
                    />
                  </div>
                </div>
                <div class="booking_text_section booking-new-payment">
                  <div className="boocking-section">
                    <div class="booking_text d-none-mobile">
                      <h2>
                        {tckDetailsOutput.moviename
                          ? tckDetailsOutput.moviename
                          : ""}{" "}
                      </h2>
                      <button type="button" class="age_btn new-payment-rating">
                        {tckDetailsOutput.mcensor
                          ? tckDetailsOutput.mcensor
                          : ""}
                      </button>
                    </div>

                    {/* mobile-view */}
                       <div className="movie-titles movie-title-payment">

                       <span class="movie-title d-none-desktop">{tckDetailsOutput.moviename
                          ? tckDetailsOutput.moviename
                          : ""}{" "}</span>
                       <span class="age-highlight d-none-desktop"><button type="button" class="age_btn new-payment-rating">
                        {tckDetailsOutput.mcensor
                          ? tckDetailsOutput.mcensor
                          : ""}
                      </button></span>
                       </div>
                       {/* mobile-view */}
                  

                    
                    <p>
                      {tckDetailsOutput.language
                        ? tckDetailsOutput.language
                        : ""}
                    </p>
                  </div>
                  <div class="booking_location_section booking-paymeent-method">
                    <div class="booking_ticket ">
                      <div class="booking_info">
                        <div class="booking_location">
                          <p>
                            {localStorage.getItem("language") !== "ar" ? (
                              "Location"
                            ) : (
                              <Text>موقع</Text>
                            )}
                          </p>

                          <h5>
                            {tckDetailsOutput.cinemaname
                              ? tckDetailsOutput.cinemaname
                              : ""}
                          </h5>
                        </div>
                        <div class="booking_time">
                          <p>
                            {localStorage.getItem("language") !== "ar" ? (
                              "Date & Time"
                            ) : (
                              <Text>التاريخ والوقت</Text>
                            )}
                          </p>

                          <h5>
                            {tckDetailsOutput.showDate
                              ? tckDetailsOutput.showDate
                              : ""}{" "}
                            |{" "}
                            {tckDetailsOutput.showTime
                              ? tckDetailsOutput.showTime
                              : ""}{" "}
                          </h5>
                        </div>
                        {/* <div class="screen">
                        <p>SCREEN</p>
                        <p>
                           {tckDetailsOutput.screenId ? tckDetailsOutput.screenId : ''}  <span> {tckDetailsOutput.experience ? <img src={tckDetailsOutput.experienceIcon} /> : ''}</span>
                        </p>
                     </div> */}
                        <div class="seats">
                          {/* <p>Seat</p> */}

                          <p>
                            {localStorage.getItem("language") !== "ar" ? (
                              "Seat"
                            ) : (
                              <Text> مقعد</Text>
                            )}
                          </p>

                          {tckDetailsOutput.seatsArr
                            ? tckDetailsOutput.seatsArr.map(function (item, i) {
                              return (
                                <>
                                  <h2>{item}</h2>
                                </>
                              );
                            })
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>
              
                
              </div>
              )}
            </div>
          </div>
        </div>

        <div className="cinescape_booking cinescape-payment-booking new-payments-booking home-content-container">
          <div className="select-payment-type">
            {/* <h2 className="text-center">SELECT PAYMENT METHOD</h2> */}

            <h2 className="text-center">
              {localStorage.getItem("language") !== "ar" ? (
                "SELECT PAYMENT METHOD"
              ) : (
                <Text>اختار طريقة الدفع</Text>
              )}
            </h2>
          </div>
          <Accordion defaultActiveKey="GATEWAY" >
            {this.state.paymodes.map(function (item, i) {
              
              if (bankofferapplied && !item.payType.includes("GATEWAY"))
                isDisabled = true;

              if (
                walletapplied &&
                (item.payType.includes("BANK") ||
                  item.payType.includes("OFFER"))
              )
                isDisabled = true;

              if (
                walletapplied &&
                !item.payType.includes("BANK") &&
                !item.payType.includes("OFFER")
              )
                isDisabled = false;

              if (
                bankofferapplied &&
                (item.payType.includes("GATEWAY") ||
                  item.payType.includes("BANK"))
              )
                isDisabled = false;

              if (giftcardapplied && item.payType.includes("BANK"))
                isDisabled = true;

              if (
                giftcardapplied &&
                (item.payType.includes("GATEWAY") ||
                  item.payType.includes("OFFER"))
              )
                isDisabled = false;
      
              return (
                <AccordionItem eventKey={item.payType}
            
>
                  <AccordionItemHeading>
                    <AccordionItemButton
                      disabled={isDisabled}
                      onClick={(event) => {
                        if (isDisabled) {
                          event.preventDefault();
                          event.stopPropagation();
                        }
                      }}
                      id={item.payType}
                    >
                      {item.name}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  {isDisabled ? (
                    ""
                  ) : (
                    <AccordionItemPanel>
                      {item.payType == "BANK" ? (
                        !bankofferapplied ? (
                          <>
                            <select
                              name="bankoffer"
                              id="bankoffer"
                              onChange={selectBankOffer}
                            >
                                    {localStorage.getItem("language") !== "ar" ? (
                              <option value="0">
                          
                                Available Bank Offers
                                </option>
                              ) : (
                                <option value="0">  العروض المتاحة</option>
                              )}{" "}
                
                          
                              {item.respPayModes[0].payModeBanks.map(function (
                                item,
                                i
                              ) {
                                return (
                                  <option value={item.id}>{item.name}</option>
                                );
                              })}
                            </select>
                            {selectedbankoffer != 0 ? (
                              <div className="card-info">
                                {/* <div className="card-number">
                                             <input type="number" onChange={bankOfferCardChange} id="quantity" className="quantity" name="quantity" min="12" max="16"></input>
                                             <label for="quantity" className="credit-info-number">Enter Card Number</label>
                                          </div> */}
                                <div className="card-number">
                                  {/* <img
                                                src={
                                                   require("../../assets/Credit Card Number Normal@2x.png")
                                                      .default
                                                }
                                             /> */}

                                  <PaymentInputsContainer>
                                    {({
                                      meta,
                                      getCardNumberProps,
                                      getExpiryDateProps,
                                      getCVCProps,
                                    }) => (
                                      <>
                                        <input
                                          {...getCardNumberProps({
                                            onChange: bankOfferCardChange,
                                          })}
                                          className="quantity"
                                          value={bankOfferCard}
                                        />
                                      </>
                                    )}
                                  </PaymentInputsContainer>
                                </div>
                                <div
                                  className="card-btn"
                                  onClick={applyBankOffer}
                                >
                                  <button
                                    type="button"
                                    class="save-btn apply-btn-card"
                                  >
                                    {localStorage.getItem("language") !== "ar" ? (
                                    "Apply"
                                  ) : (
                                    <Text> يتقدم</Text>
                                  )}{" "}
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <div className="card-info">
                            <p>Bank Offer Applied</p>
                            <div className="card-btn">
                              <button
                                type="button"
                                class="save-btn apply-btn-card"
                                onClick={removeBankOffer}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        )
                      ) : (
                        ""
                      )}

                      {item.payType == "OFFER" ? (
                        <>
                          <div className="add-voucher-card">
                            {item.respPayModes.map(function (item, i) {
                              return (
                                <div className="add-gift-card">
                                  <div
                                    className={`payment-option pointer-cursor ${selectedoffer == item.id
                                      ? "payment-border"
                                      : ""
                                      }`}
                                    onClick={() => selectOffer(item.id)}
                                  >
                                    <img
                                      src={selectedoffer === item.id ? item.activeImageUrl : item.imageUrl}

                                    />
                                    <p>{item.name}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {!giftcardapplied && selectOffer != "" ? (
                            <div className="card-info">
                              <div className="card-number">
                                <PaymentInputsContainer>
                                  {({
                                    meta,
                                    getCardNumberProps,
                                    getExpiryDateProps,
                                    getCVCProps,
                                  }) => (
                                    <>
                                      <input
                                        {...getCardNumberProps({
                                          onChange: giftOfferCardChange,
                                        })}
                                        className="quantity"
                                        value={giftOfferCard}
                                        maxLength={16}
                                      />
                                    </>
                                  )}
                                </PaymentInputsContainer>
                              </div>
                              <div className="card-btn">
                                <button
                                  type="button"
                                  onClick={() => applyGiftcard(item.id)}
                                  class="save-btn apply-btn-card"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="card-info">
                              
                              <p>

                              {localStorage.getItem("language") !== "ar" ? (
                                "Gift Card Applied"
                              ) : (
                                <Text> تم تطبيق بطاقة الهدايا</Text>
                              )}
                              </p>
                              <div className="card-btn">
                                <button
                                  type="button"
                                  class="save-btn apply-btn-card"
                                  onClick={() => removeGiftcard(item.id)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {item.payType == "WALLET" ? (
                        <>
                          <div className="card-info card-balance">
                            <div className="card-number ">
                              <div className="add-gift-card">
                                <div
                                  className="payment-option pointer-cursor"
                                  onClick={() =>
                                    selectWallet(
                                      item.id,
                                      item.respPayModes[0].balance
                                    )
                                  }
                                >
                                  <img
                                   
                                    src={selectedwallet === item.id ?  item.respPayModes[0].activeImageUrl :  item.respPayModes[0].imageUrl}

                                  />
                                  <p>
                                  {localStorage.getItem("language") !== "ar" ? (
                                    "Balance"
                                  ) : (
                                    <Text> توازن</Text>
                                  )}{" "}
                                 
                                     {item.respPayModes[0].balance}</p>
                                </div>
                              </div>
                            </div>
                            {walletapplied  ? (
                              <div className="card-btn">
                                <button
                                  type="button"
                                  onClick={() => removeWallet(item.id)}
                                  class="save-btn apply-btn-card"
                                >
                                  
                                  {localStorage.getItem("language") !== "ar" ? (
                                    "Remove"
                                  ) : (
                                    <Text>  يزيل</Text>
                                  )}{" "}
                                
                                </button>
                              </div>
                            ) : (
                              <div className="card-btn">
                                <button
                                  type="button"
                                  onClick={() =>
                                    applyWallet(
                                      item.id,
                                      item.respPayModes[0].balance
                                    )
                                  }
                                  class="save-btn apply-btn-card"
                                >
                                  {localStorage.getItem("language") !== "ar" ? (
                                    "Apply"
                                  ) : (
                                    <Text> يتقدم</Text>
                                  )}{" "}
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {item.payType == "GATEWAY" ? (
                        <>
                          <div className="booked-time-full payment-food payment-last">
                            <div className="fixed-payment-contain ">
                              <div className="payment-options">
                                {item.respPayModes.map(function (item, i) {
                                  let show = true;
                                  if (((item.name == "KNET" || item.name=="كي نت") && knetallowed) || (item.name == 'Credit Card' || item.name == 'بطاقة ائتمانية') )
                                    show = true;
                                  else
                                    show = false;

                                  return (
                                    <div
                                      className={`payment-option pointer-cursor ${selectedcard == item.name
                                        ? "payment-border"
                                        : ""
                                        }`}
                                      disabled={!show}
                                      onClick={() =>
                                        selectCard(show, item.name)
                                      }
                                    >
                                      {(item.name == "KNET" || item.name=="كي نت") && knetallowed ? <img
                                     
                                        src={selectedcard === item.name ? item.activeImageUrl : item.imageUrl}

                                      /> :
                                        <img
                                         
                                          src={selectedcard === item.name ? item.activeImageUrl : item.imageUrl}

                                        />}
                                      <p>{item.name}</p>
                                    </div>
                                  );
                                })}
                              </div>
                              {selectedcard == 'Credit Card' || selectedcard == 'بطاقة ائتمانية' ? (
                                <div className="card-details-container">
                                  <div className="input-box-card input-ccv">
                                    <img
                                 
                                      src={selectedcard === item.name ? item.activeImageUrl : item.imageUrl}

                                    />
                                    <input
                                      type="text"
                                      name=""
                                      placeholder="Cardholder Name"
                                      onChange={cardNameChange}
                                    />
                                  </div>
                                  <div className="input-box-card input-ccv">
                                    <img
                                   
                                      src={selectedcard === item.name ? item.activeImageUrl : item.imageUrl}

                                    />
                                    <PaymentInputsContainer>
                                      {({
                                        meta,
                                        getCardNumberProps,
                                        getExpiryDateProps,
                                        getCVCProps,
                                      }) => (
                                        <>
                                          <input
                                            {...getCardNumberProps({
                                              onChange: cardNumberChange,
                                            })}
                                            value={cardNumber}
                                            maxLength={19}
                                          />
                                        </>
                                      )}
                                    </PaymentInputsContainer>
                                  </div>
                                  <div className="card-types">
                                    <img
                                      src={
                                        require("../../assets/express-icon (2).png")
                                          .default
                                      }
                                    />
                                    &nbsp;
                                    <img
                                      src={
                                        require("../../assets/express-icon (4).png")
                                          .default
                                      }
                                    />
                                  </div>
                                  <div className="input-box-card">
                                    <div className="input-box-part input-ccv input-date">
                                      <img
                                        src={
                                          require("../../assets/Credit Card Expiry Date  Normal – 1@2x.png")
                                            .default
                                        }
                                      />
                                      <PaymentInputsContainer>
                                        {({
                                          meta,
                                          getCardNumberProps,
                                          getExpiryDateProps,
                                          getCVCProps,
                                        }) => (
                                          <>
                                            <input
                                              {...getExpiryDateProps({
                                                onChange: expirationDateChange,
                                              })}
                                              value={expiryDate}
                                            />
                                          </>
                                        )}
                                      </PaymentInputsContainer>
                                    </div>
                                    <div className="input-box-part input-ccv">
                                      <img
                                        src={
                                          require("../../assets/express-icon (3).png")
                                            .default
                                        }
                                      />
                                      <PaymentInputsContainer>
                                        {({
                                          meta,
                                          getCardNumberProps,
                                          getExpiryDateProps,
                                          getCVCProps,
                                        }) => (
                                          <>
                                            <input
                                              {...getCVCProps({
                                                onChange: cvNumberChange,
                                              })}
                                              value={cvNumber}
                                            />
                                          </>
                                        )}
                                      </PaymentInputsContainer>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </AccordionItemPanel>
                  )}
                </AccordionItem>
              );
            })}
          </Accordion>
          <div className="price-total-info price-new-total">
            <div className="price-total">
              <div className="all-total-price">

              {localStorage.getItem("onlyFood") ? (
                ""
              ) : (
                <div className="total-tickets-price ticket-new">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Ticket Price"
                    ) : (
                      <Text>سعر التذكرة</Text>
                    )}{" "}
                    <span>
                      {tckDetailsOutput.ticketPrice} x{" "}
                      {tckDetailsOutput.numofseats}
                    </span>
                  </p>
                  <p>{tckDetailsOutput.totalTicketPrice}</p>
                </div>
              )}

                


                {tckDetailsOutput.foodTotal && tckDetailsOutput.foodTotal != 'KWD 0.000'

                  ?
                  <div className="total-tickets-price ticket-new">
                    <p>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Food Price"
                      ) : (
                        <Text>سعر الغذاء</Text>
                      )}{" "}
                    </p>
                    <p>
                      {tckDetailsOutput.foodTotal
                        ? tckDetailsOutput.foodTotal
                        : ""}
                    </p>
                  </div> : ''}
                {bankofferapplied || giftcardapplied || walletapplied ?
                  <div className="total-tickets-price ticket-new">
                    <p>
                      {bankofferapplied ? "Bank Offer Applied" : ""}
                      {giftcardapplied ? "Gift Card Applied" : ""}
                      {walletapplied ? "Wallet Applied" : ""}
                    </p>
                    <p>
                      {walletapplied ? this.state.paidFull ? tckDetailsOutput.totalPrice : this.state.walletAmount : ""}
                      {giftcardapplied ? this.state.paidFull ? tckDetailsOutput.totalPrice : '' : ""}

                      {bankofferapplied ? this.state.bankDiscount : ""}
                    </p>
                  </div> : ''}

                <div className="grand-total grand-new">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Total amount to be paid"
                    ) : (
                      <Text>المجموع الإجمالي</Text>
                    )}{" "}
                  </p>
                  <p>
                    <b>
                      {" "}
                      {remainingAmount == 0
                        ? tckDetailsOutput.totalPrice
                        : remainingAmount == null ? "KWD 0" : remainingAmount}
                    </b>
                  </p>
                </div>
              </div>
            </div>

            <div className="time-info">

              <div className="proceed-food-container">
                
              {localStorage.getItem("onlyFood") ? (
                ""
              ) : (
                <p>
                  <span className="time-remaining">Time remaining </span>{" "}
                  {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}
                </p>
              )}


                
                <button
                  className="btnc btnc-ghost btn-cancel"
                  onClick={this.canceltransPopup}
                >
                  Cancel
                </button>
                {(selectedcard != "" || this.state.payDone) && this.state.showProceed ? (
                  <button
                    className="btnc btnc-ghost proceed-btn"

                    onClick={proceed}
                  >
                    Proceed
                  </button>) : <button
                    className="btnc btnc-ghost"

                    disabled
                  >
                  Proceed
                </button>}
              </div>

            </div>
          </div>
        </div>
        {this.state.showModalFood ? (
          <>
            <Modal
              isOpen={this.state.showModalFood}
              contentLabel="Example Modal"
              portalClassName="food-modal"
            >
              {" "}
              <button
                className="trailer-cross"
                onClick={() => this.setState({ showModalFood: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              {/* 
   <TrailerModal trailerUrl={this.state.trailerUrl}  name={this.state.name} />
   */}
              <div className="food-modal-content">
                <div className="food-modal-banner">
                  <img src={this.state.modalItem.itemPopupImageUrl} />
                </div>
                <div className="food-modal-content">
                  <div className="food-modal-heading">
                    <h3>{this.state.modalItem.description}</h3>
                    <div className="food-modal-price">
                      <h3>{this.state.modalItem.itemPrice}</h3>
                    </div>
                  </div>
                  <div className="food-modal-flavour">
                    {this.state.modalItem.foodtype == "Flavour" ? (
                      <div className="flavour-select-container">
                        {this.state.modalItem.alternateItems.map(function (
                          item,
                          i
                        ) {
                          let active = "";
                          if (item.description == selectedFlavor)
                            active = "active";
                          return (
                            <div
                              className={`flavour-select-item ${active}`}
                              onClick={() =>
                                selectflavor(
                                  item.description,
                                  item.priceInCents
                                )
                              }
                            >
                              {item.description}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        {this.state.modalItem.packageChildItems.map(function (
                          item,
                          i
                        ) {
                          return (
                            <>
                              <h4 className="combo-head">{item.description}</h4>
                              <div className="flavour-select-container">
                                <div className="food-item-container">
                                  {item.alternateItems.map(function (
                                    food,
                                    index
                                  ) {
                                    let active = "";
                                    if (selectedCombo.indexOf(food) > -1)
                                      active = "food_box_active";
                                    return (
                                      <>
                                        <div
                                          className={`food-combo-preview ${active}`}
                                          onClick={() => selectCombo(food, i)}
                                        >
                                          <div className="food-combo-content">
                                            <h4>{food.description}</h4>
                                            {/* 
                  <p>{food.description}</p>
                  */}
                                            <div className="food-combo-contain">
                                              <div className="food-price">
                                                <p>{food.itemPrice}</p>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                  {this.state.selectedFlavor.length > 0 &&
                    this.state.modalItem.foodtype == "Flavour" ? (
                    <div className="food-modal-summary">
                      <p className="red-info">
                        {localStorage.getItem("language") !== "ar" ? (
                          "YOU SELECTED"
                        ) : (
                          <Text>أنت الذي اخترته</Text>
                        )}
                      </p>
                      <div className="food-after-select">
                        <div className="food-selected">
                          <div className="food-selected-left">
                            <p>{this.state.selectedFlavor}</p>
                          </div>
                          <div className="food-selected-right">
                            <p>
                              {this.state.quantity} x{" "}
                              {this.state.modalItem.itemPrice} = KD{" "}
                              {this.state.quantity > 0
                                ? (this.state.quantity *
                                  this.state.modalItem.priceInCents) /
                                1000
                                : this.state.modalItem.priceInCents / 1000}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="food-counter-container">
                        <div className="food-counter">
                          <i
                            class="fa fa-plus"
                            aria-hidden="true"
                            onClick={() => increasequantity()}
                          ></i>
                          <input type="text" value={this.state.quantity} />
                          <i
                            class="fa fa-minus"
                            aria-hidden="true"
                            onClick={() => decreasequantity()}
                          ></i>
                        </div>
                        <button
                          className="btnc btnc-primary btnc-fooditem"
                          onClick={() =>
                            this.addConcessionFoods(
                              "Flavour",
                              this.state.modalItem.id,
                              this.state.modalItem.description,
                              this.state.modalItem.descriptionAlt,
                              this.state.modalItem.headOfficeItemCode,
                              this.state.modalItem.itemPrice,
                              this.state.modalItem.itemType,
                              this.state.modalItem.parentId,
                              this.state.modalItem.priceInCents,
                              this.state.quantity
                            )
                          }
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            "ADD ITEM"
                          ) : (
                            <Text>اضافة عنصر</Text>
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.selectedCombo.length > 0 &&
                    this.state.modalItem.foodtype == "combo" ? (
                    <div className="food-modal-summary">
                      <p className="red-info">
                        {localStorage.getItem("language") !== "ar" ? (
                          "YOU SELECTED"
                        ) : (
                          <Text>أنت الذي اخترته</Text>
                        )}
                      </p>
                      <div className="food-after-select">
                        <div className="food-selected">
                          <div className="food-selected-left">
                            <p>{this.state.selectedFlavor}</p>
                          </div>
                          <div className="food-selected-right">
                            <p>
                              {this.state.quantity} x{" "}
                              {this.state.modalItem.itemPrice} = KD{" "}
                              {this.state.quantity > 0
                                ? (this.state.quantity *
                                  this.state.modalItem.priceInCents) /
                                1000
                                : this.state.modalItem.priceInCents / 1000}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="food-counter-container">
                        <div className="food-counter">
                          <i
                            class="fa fa-plus"
                            aria-hidden="true"
                            onClick={() => increasequantity()}
                          ></i>
                          <input type="text" value={this.state.quantity} />
                          <i
                            class="fa fa-minus"
                            aria-hidden="true"
                            onClick={() => decreasequantity()}
                          ></i>
                        </div>
                        <button
                          className="btnc btnc-primary btnc-fooditem"
                          onClick={() =>
                            this.addConcessionFoods(
                              "Combo",
                              this.state.modalItem.id,
                              this.state.modalItem.description,
                              this.state.modalItem.descriptionAlt,
                              this.state.modalItem.headOfficeItemCode,
                              this.state.modalItem.itemPrice,
                              this.state.modalItem.itemType,
                              this.state.modalItem.parentId,
                              this.state.modalItem.priceInCents,
                              this.state.quantity
                            )
                          }
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            "ADD ITEM"
                          ) : (
                            <Text>اضافة عنصر</Text>
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Modal>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}
export default Payment;
