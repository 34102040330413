import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import Text from "react-text";
import axios from "axios";
import parse from 'html-react-parser';

class PromotionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerId: this.props.match.params.id,
      offersDetails: {},
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("token");

    axios
      .post(`${Constants.api}more/offer/detail?id=` + this.state.offerId, "", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,
          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        this.setState({ offersDetails: res.data.output });
        console.log(this.state.offersDetails);
      });
  }
  render() {

    const { webImageUrl, name, validfrom, validto, type, webDescription, detailText } =
      this.state.offersDetails;
    // Ensure detailText has line breaks replaced by <br>
    const formattedDetailText = detailText
      ? detailText.replace(/\r\n/g, "<br>")
      : "";

    return (
      <>
        <div className="offers-description">
          <div className="offers-background">
            <img src={webImageUrl} alt={name} />
          </div>
          <div className="home-content-container offer-vip">
            <p className="valid-date">{name}</p>
            {/* <small>
              {validfrom} to {validto} &nbsp;&nbsp;&nbsp;{type}
            </small> */}
            <div className="offers-desc">
            
                             {formattedDetailText ? parse(formattedDetailText) : ""}

               
            </div>
          </div>
        </div>

      </>
    );
  }
}
export default PromotionDetails;
