import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import Swal from "sweetalert2";

import moment from "moment";
import Text from "react-text";
import { Loading } from "../header/loader.component";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
let foodarabic = localStorage.getItem("language") === 'ar' ? "foodarabic" : '';
class Food extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showModalFood: false,
         tckDetailsOutput: {},
         selectSeat: [],
         tab: [],
         cinemaId: this.props.match.params.ccode,
         showcart: false,
         modalType: "",
         modalItem: {},
         selectedFlavor: "",
         selectedCombo: [],
         quantity: 0,
         calculatedTotal: 0,
         concessionFoods: [],
         time: {},
         //seconds: 300,
         showcreditcard: false,
         seconds: parseInt(localStorage.getItem('timeout')) * 60,
         selectedpayment: "",
         loading: false,
         sessionId: localStorage.getItem('sessionID') != "undefined" ? localStorage.getItem('sessionID') : '',
         selectedTab: "",
         foodnotificationhide: false,
         selectioncomplete: false,
         onlyFood: false,
         oncetimer: true
      };
      this.timer = 0;
      this.startTimer = this.startTimer.bind(this);
      this.countDown = this.countDown.bind(this);
      this.flavorvalidator = new SimpleReactValidator();

   }
   componentDidMount() {

      const tckDetailsRequest = async () => {
         try {
            console.log(this.state.moviename);
            console.log(this.state.mcode);
            // const resp =
            const token = localStorage.getItem("token");

            await axios
               .post(
                  Constants.api + "content/trans/tcksummary/",
                  {
                     bookingId: localStorage.getItem("bookingid"),
                     transid: localStorage.getItem("transid"),
                     userId: localStorage.getItem("userid"),
                  },
                  {
                     headers: {
                        "Accept-Language": localStorage.getItem("language"),
                        Authorization: `Bearer ${token}`,
                        appversion: Constants.av,
                        platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                     },
                  }
               )
               .then((resp) => {
                  //  casetype array
                  if (resp.data.result === "success") {
                     let tckDetailsArray = [];
                     this.setState({
                        tckDetailsOutput: resp.data.output,
                        selectSeat: resp.data.output.seatsArr,
                     });
                     this.setState({
                        totalPrice: resp.data.output.totalTicketPrice,
                     });
                     localStorage.setItem("bookingid", resp.data.output.bookingId);
                  } else {
                     swal(resp.data.msg);
                     this.setState({
                        loading: false,
                     });
                  }
               });
         } catch (err) {
            console.error(err);
         }
      };
      if (localStorage.getItem('onlyFood'))
         this.setState({ onlyFood: true });
      else
         tckDetailsRequest();
      const getfood = async () => {
         try {
            console.log(this.state.moviename);
            console.log(this.state.mcode);
            const token = localStorage.getItem("token");

            await axios
               .post(
                  Constants.api + "content/food/getfood/",
                  {
                     cinemaId: this.state.cinemaId,
                  },
                  {
                     headers: {
                        "Accept-Language": localStorage.getItem("language"),
                        Authorization: `Bearer ${token}`,
                        appversion: Constants.av,
                        platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                     },
                  }
               )
               .then((resp) => {
                  if (resp.data.result === "success") {
                     console.log(resp.data);
                     this.setState({ tab: resp.data.output.concessionTabs });
                  } else {
                     swal(resp.data.msg);
                     this.setState({
                        loading: 0,
                     });
                  }
               }).catch((err) => {
                  window.location = "/";
               });
         } catch (err) {
            console.error(err);
         }
      };
      getfood();
      
      if (localStorage.getItem('onlyFood'))
         console.log(localStorage.getItem('onlyFood'))
      else
         this.startTimer();
      
   }
   canceltrans = () => {
      // swal({
      //    title: "",
      //    text: "Are you sure you want to cancel transaction?",
      //    type: ""
      // }).then(function () {
      //    window.location = localStorage.getItem("moviesessionPath");

      // });


      Swal.fire({
         title: '',
         text: 'Are you sure you want to cancel transaction?',
         icon: 'info',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes',
      }).then((result) => {
         if (result.isConfirmed) {

            if (localStorage.getItem('onlyFood')) {
               window.location = "/";
            } else {
               window.location = localStorage.getItem("moviesessionPath");
            }

            
         } else {
           

         }
      });
   }
   submitFood = () => {

      if (this.state.concessionFoods.length > 0) {
         this.setState({ loading: true });

         const requestObject = {
            booktype: this.state.onlyFood ? "FOOD" : "BOOKING",
            cinemaId: this.state.cinemaId,
            concessionFoods: this.state.concessionFoods,
          };
          
          if (this.state.onlyFood) {
            requestObject.transid = 0;
          } else {
            requestObject.sessionid = this.state.sessionId;
            requestObject.transid = localStorage.getItem("transid");
          }
          const token = localStorage.getItem("token");
          axios
            .post(
               Constants.api + "content/food/addconcession/",requestObject,
               {
                  headers: {
                     "Accept-Language": localStorage.getItem("language"),
                     Authorization: `Bearer ${token}`,
                     appversion: Constants.av,
                     platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                  },
               }
            )
            .then((resp) => {
               this.setState({ loading: false });

               if (resp.data.output != null){
                  localStorage.setItem("transid", resp.data.output.transid);
                  window.location = "/payment" + "/" + this.state.cinemaId;
               }
               else swal(resp.data.msg)
            });
      } else {
         if (localStorage.getItem('onlyFood')) {
            swal({
               title: "",
               text: "Please select at least one food",
               type: ""
            }).then(function () {
               

            });
         } else {
            window.location = "/payment" + "/" + this.state.cinemaId;
         }
      }

         
      // if (this.state.selectedpayment == "Wallet") this.clubPayment();
      // if (this.state.selectedpayment == "KNET") this.knetPayment();

      localStorage.setItem('timeout', this.state.seconds / 60);
   };
   startTimer = () => {
      if (this.timer == 0 && this.state.seconds > 0) {
         this.timer = setInterval(this.countDown, 1000);
      }
   };

   countDown = () => {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1;
      this.setState({
         time: this.secondsToTime(seconds),
         seconds: seconds,
      });
      // Check if we're at zero.
      if (seconds == 60) {
         if (this.state.oncetimer) {


            Swal.fire({
               title: '',
               text: 'Are you still here?',
               icon: 'info',
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#d33',
               confirmButtonText: 'Yes',
            }).then((result) => {
               if (result.isConfirmed) {
                  // User clicked the "Yes" button, you can perform the action here
                  this.setState({ seconds: this.state.seconds + 59, oncetimer: false });

                  this.startTimer();
               } else {
                  //  this.canceltrans();
                  // User clicked the "No" button or closed the dialog

               }
            });
         }
         else this.canceltrans();
         // window.location = "/";

         //clearInterval(this.timer);
      }
      if (this.state.seconds == 0) {
         Swal.fire({
            title: '',
            text: 'Session timed out please try again!',
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
         }).then((result) => {
            if (result.isConfirmed) {
               // User clicked the "Yes" button, you can perform the action here
               //this.canceltrans();

               window.location = localStorage.getItem("moviesessionPath");
            } else {
               this.canceltrans();
               // User clicked the "No" button or closed the dialog

            }
         });
      }

   };

   secondsToTime = (secs) => {
      let hours = Math.floor(secs / (60 * 60));
      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);
      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);
      if (hours < 10) hours = '0' + hours;
      if (minutes < 10) minutes = '0' + minutes;
      if (seconds < 10) seconds = '0' + seconds;

      let obj = {
         h: hours,
         m: minutes,
         s: seconds,
      };
      return obj;
   };
   
   addConcessionFoods = (
      foodType,
      itemid,
      title,
      description,
      descriptionAlt,
      headOfficeItemCode,
      itemPrice,
      itemType,
      parentId,
      priceInCents,
      quantity,
      foodimage) => {
      if (this.flavorvalidator.allValid()) {


         if (itemPrice.includes('-')) {
            itemPrice = 'KWD ' + (priceInCents / 100).toFixed(3);
        }
        

         this.setState({ foodnotificationhide: false,selectioncomplete:false });

         // this.setState({ showcart: true })
         let concessionFoods = this.state.concessionFoods;
         var item = {};
         if (foodType == "Flavour" || foodType == "Individual") {
            item = {
               title: title,
               description: description,
               descriptionAlt: descriptionAlt,
               headOfficeItemCode: headOfficeItemCode,
               itemId: itemid,
               itemPrice: itemPrice,
               itemType: itemType,
               items: [],
               parentId: parentId,
               priceInCents: priceInCents,
               quantity: quantity,
               headOfficeItemCode: headOfficeItemCode,
               itemImageUrl: foodimage
            };
         } else {
            item = {
               title: title,
               description: description,
               descriptionAlt: descriptionAlt,
               headOfficeItemCode: headOfficeItemCode,
               itemId: itemid,
               itemPrice: itemPrice,
               itemType: foodType,
               items: this.state.selectedCombo,
               parentId: parentId,
               priceInCents: priceInCents,
               quantity: quantity,
               headOfficeItemCode: headOfficeItemCode,
               itemImageUrl: foodimage

            };
         }
         let found = concessionFoods.some((el) => el.itemId === itemid);
         console.log(found);
         if (!found) concessionFoods.push(item);
         else {
            var __FOUND = -1;
            for (var i = 0; i < concessionFoods.length; i++) {
               if (concessionFoods[i].itemId == itemid) {
                  // __FOUND is set to the index of the element
                  __FOUND = i;
                  break;
               }
            }
            concessionFoods[__FOUND].quantity = parseInt(
               concessionFoods[__FOUND].quantity + 1
            );
         }
         this.setState({
            concessionFoods: concessionFoods,
            showModalFood: false,
            selectedCombo: [],
            selectedFlavor: "",
         });
         console.log("concessionFoods");

         console.log(concessionFoods);
         setTimeout(
            function () {
               this.setState({ foodnotificationhide: true, quantity: 0 });
            }
               .bind(this),
            2000
         );
      } else this.flavorvalidator.showMessages();
      this.forceUpdate();


   };

   render() {


      let concessionFoods = this.state.concessionFoods;
      let foodnotificationhide = this.state.foodnotificationhide;
      let selectedTab = this.state.selectedTab;
      let selectTab = (i) => {
         this.setState({ selectedTab: i })
      }
      let packageChildItemsLength = this.state.modalItem && this.state.modalItem.packageChildItems ? this.state.modalItem.packageChildItems.length : 0;

      const responsive2 = {
         superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
         },
         desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
         },
         tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
         },
         mobile: {
            breakpoint: { max: 464, min: 0 },
            items: false,
         },
      };
      let tckDetailsOutput = this.state.tckDetailsOutput;
      let openModal = (item, foodType) => {
         if (foodType == "Flavour" || foodType == "combo")
            console.log(item)

            this.setState({
               showModalFood: true,
               modalItem: item,
            });
         if (foodType == "Individual")
            this.addConcessionFoods(
               "Individual",
               item.id,
               item.title,
               item.description,
               item.descriptionAlt,
               item.headOfficeItemCode,
               item.itemPrice,
               item.itemType,
               item.parentId,
               item.priceInCents,
               1,
               item.itemImageUrl

            );
      };
      let selectflavor = (id, item, price, headOfficeItemCode, selectedFlavorarabic, image) => {

         this.setState((prevState) => ({
            modalItem: {
              ...prevState.modalItem,
              priceInCents: price,
            //  itemPrice:price
            },
          }));
         if (this.state.quantity == 0)
            this.setState({ quantity: 1 });
         this.setState({
           // modalItem:item,
            itemId:item.id,
            selectedFlavorID: id,
            selectedheadOfficeItemCode: headOfficeItemCode,
            selectedFlavor: item,
            priceInCents: price,
            selectedFlavorarabic: selectedFlavorarabic,
            foodimage: image,
            selectioncomplete: true
         });
      };
      let selectCombo = (item, index, count) => {
         let selectedCombo = this.state.selectedCombo;
         selectedCombo[index] = item;
         selectedCombo[index].itemId = item.id
         this.setState({
            selectedCombo,
            selectioncomplete: true

         });
         console.log("selectedCombo");
         console.log(selectedCombo);
         if (this.state.quantity == 0 && count == selectedCombo.length)
            this.setState({ quantity: 1 });
      };
      let increasequantity = () => {
         let quantity = parseInt(this.state.quantity + 1);
         this.setState({ quantity });
      };
      let decreasequantity = () => {
         if (this.state.quantity > 1) {
            let q = this.state.quantity - 1;
            this.setState({ quantity: q });
         }
      };
      let increaseFoodQuantity = (i) => {
         let concessionFoods = this.state.concessionFoods;
         concessionFoods[i].quantity = parseInt(concessionFoods[i].quantity + 1);
         this.setState({ concessionFoods });
      };
      let decreaseFoodQuantity = (i) => {
         let concessionFoods = this.state.concessionFoods;
         if (concessionFoods[i].quantity > 1)
            concessionFoods[i].quantity = parseInt(concessionFoods[i].quantity - 1);
         else concessionFoods.splice(i, 1);
         this.setState({ concessionFoods });
      };
      let removeFood = (i) => {
         let concessionFoods = this.state.concessionFoods;
         concessionFoods.splice(i, 1);
         this.setState({ concessionFoods });
      };
      let selectedFlavor = this.state.selectedFlavor;
      let selectedCombo = this.state.selectedCombo;
      let fprice = 0;
      let fdprice = 0;
      let foodieprice = 0;

      let fpriceout = 0;
      this.state.concessionFoods.map(function (item, i) {
         fpriceout += item.priceInCents * item.quantity;
      })
      return (
         <>
            <div className="seat-banner d-none-mobile">
               <img src={require("../../assets/food-banner.png").default} alt="" />
            </div>
            <div className="home-content-container food-content-containerpage">
               <div className="food-container">
                  <div className="proceed-skip-food">
                     {!this.state.onlyFood ?
                        <button
                           className="btnc btnc-primary"
                           onClick={() =>
                              window.location = "/payment" + "/" + this.state.cinemaId
                           }
                        >

                           {localStorage.getItem("language") !== "ar" ? (
                              "SKIP & PROCEED"
                           ) : (
                              <Text>التخطي والمتابعة</Text>
                           )}
                        </button>
                        : ''}
                  </div>
                  {/* desktop-view */}
                  <div className="food-category ">
                     <Tabs>
                        <div className="slider-carousel">
                           <Carousel
                              className="banner-slider"
                              swipeable={false}
                              draggable={false}
                              arrows={true}
                              showDots={false}
                              responsive={responsive2}
                              ssr={true} // means to render carousel on server-side.
                              infinite={false}
                              autoPlay={false}
                              keyBoardControl={true}
                              containerClass="carousel-container"
                              removeArrowOnDeviceType={[]}
                              deviceType={this.props.deviceType}
                              dotListClass="custom-dot-list-style"
                              itemClass="carousel-item-padding-40-px"
                           >
                              {this.state.tab.map(function (item, i) {
                                 return (
                                    <>
                                       <Tab>
                                          <div onClick={() =>
                                             selectTab(i)} className={`food-category-preview ${i == selectedTab ? 'active' : ''}`}>
                                             <img src={item.tabImageUrl} />
                                             <p>{item.name}</p>
                                          </div>
                                       </Tab>
                                    </>
                                 );
                              })}
                           </Carousel>
                           <div className="food-category-panel food-tab-desktop">
                              {this.state.tab.map(function (tabs, j) {
                                 if (j == selectedTab) {
                                    return (
                                       <>
                                          <TabPanel>
                                             <div className="food-item-container d-none-mobile-food">
                                                {tabs.concessionItems.map(function (item, i) {
                                                   let itemExist = concessionFoods.some(items => items.itemId === item.id);
                                                   return (
                                                      <>
                                                         <div className="food-item-preview">
                                                            <div className="food-item-img">
                                                               <img src={item.itemImageUrl} />
                                                            </div>
                                                            <div className="food-item-content">
                                                               {item.title != item.description && item.title != null ?
                                                                  <>
                                                                     <h4>{item.title}</h4>
                                                                     <p>{item.description}</p>
                                                                  </>
                                                                  :
                                                                  <h4>{item.description}</h4>
                                                               }

                                                               <div className="food-price-contain">
                                                                  <div className="food-price">
                                                                     <p>{item.itemPrice}</p>
                                                                     {!itemExist ?

                                                                        <button
                                                                           className="btnc btnc-primary btn-add-food"
                                                                           onClick={() =>
                                                                              openModal(item, item.foodtype)
                                                                           }
                                                                        >

                                                                           {localStorage.getItem("language") !==
                                                                              "ar" ? (
                                                                              "Add"
                                                                           ) : (
                                                                              <Text>يضيف</Text>
                                                                           )}
                                                                        </button> :
                                                                        concessionFoods.map(function (food, f) {
                                                                           fprice += (food.priceInCents) * food.quantity;
                                                                           if (food.itemId == item.id) {
                                                                              return (
                                                                                 <div className="food-quantity-container">

                                                                                    <div className="food-inc-btn">

                                                                                       <div class="counter-button">
                                                                                          <button onClick={() => decreaseFoodQuantity(f)}>
                                                                                             -
                                                                                          </button>
                                                                                          <input
                                                                                             type="text"
                                                                                             name="ticket-count"
                                                                                             id="ticket-count"
                                                                                             placeholder={food.quantity}
                                                                                          />
                                                                                          <button onClick={() => increaseFoodQuantity(f)}>
                                                                                             +
                                                                                          </button>
                                                                                       </div>
                                                                                    </div>

                                                                                 </div>
                                                                              );
                                                                           }
                                                                        })
                                                                     }






                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </>
                                                   );
                                                })}
                                             </div>
                                          </TabPanel>
                                       </>
                                    );
                                 }
                              })}
                           </div>
                        </div>
                     </Tabs>
                  </div>
                  {/* desktop-view */}
                  {/* mobile-view-tab */}
                  <div className="food-category d-none-desktop-food">
                     <Tabs>
                        <div className="food-section ">
                           <div className="food-category-tab ">
                              <TabList className="food-tablist">
                                 {this.state.tab.map(function (item, i) {
                                    return (
                                       <>
                                          <Tab>
                                             <div className="food-category-preview">
                                                <img src={item.tabImageUrl} />
                                                <p>{item.name}</p>
                                             </div>
                                          </Tab>
                                       </>
                                    );
                                 })}
                              </TabList>
                           </div>
                           <div className="food-category-panel">
                              {this.state.tab.map(function (tabs, j) {
                                 return (
                                    <>
                                       <TabPanel>
                                          <div className="food-item-container">
                                             {tabs.concessionItems.map(function (item, i) {
                                                let itemExist = concessionFoods.some(items => items.itemId === item.id);
                                                return (
                                                   <>
                                                      <div className="food-item-preview">
                                                         <div className="food-item-img">
                                                            <img src={item.itemImageUrl} />
                                                         </div>
                                                         <div className="food-item-content">
                                                            {item.title != item.description && item.title != null ?
                                                               <>
                                                                  <h4>{item.title}</h4>
                                                                  <p>{item.description}</p>
                                                               </>
                                                               :
                                                               <h4>{item.description}</h4>
                                                            }
                                                            <div className="food-price-contain">
                                                               <div className="food-price">
                                                                  <p>{item.itemPrice}</p>

                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="item-btn">
                                                            {/* <button
                                                               className="btnc btnc-primary btn-add-food"
                                                               onClick={() =>
                                                                  openModal(item, item.foodtype)
                                                               }
                                                            >

                                                               {localStorage.getItem("language") !==
                                                                  "ar" ? (
                                                                  "Add"
                                                               ) : (
                                                                  <Text>يضيف</Text>
                                                               )}
                                                            </button> */}

                                                            {!itemExist ?
                                                               <button
                                                                  className="btnc btnc-primary btn-add-food"
                                                                  onClick={() =>
                                                                     openModal(item, item.foodtype)
                                                                  }
                                                               >

                                                                  {localStorage.getItem("language") !==
                                                                     "ar" ? (
                                                                     "Add"
                                                                  ) : (
                                                                     <Text>يضيف</Text>
                                                                  )}
                                                               </button> :
                                                               concessionFoods.map(function (food, f) {
                                                                  fprice += (food.priceInCents) * food.quantity;
                                                                  if (food.itemId == item.id) {
                                                                     return (
                                                                        <div className="food-quantity-container">

                                                                           <div className="food-inc-btn">

                                                                              <div class="counter-button">
                                                                                 <button onClick={() => decreaseFoodQuantity(f)}>
                                                                                    -
                                                                                 </button>
                                                                                 <input
                                                                                    type="text"
                                                                                    name="ticket-count"
                                                                                    id="ticket-count"
                                                                                    placeholder={food.quantity}
                                                                                 />
                                                                                 <button onClick={() => increaseFoodQuantity(f)}>
                                                                                    +
                                                                                 </button>
                                                                              </div>
                                                                           </div>

                                                                        </div>
                                                                     );
                                                                  }
                                                               })
                                                            }
                                                         </div>
                                                      </div>
                                                   </>
                                                );
                                             })}
                                          </div>
                                       </TabPanel>
                                    </>
                                 );
                              })}
                           </div>
                        </div>
                        {/* mobile-view-tab */}
                        <div className="slider-carousel">
                           <Carousel
                              className="banner-slider"
                              swipeable={false}
                              draggable={false}
                              arrows={true}
                              showDots={false}
                              responsive={responsive2}
                              ssr={true} // means to render carousel on server-side.
                              infinite={false}
                              autoPlay={false}
                              keyBoardControl={true}
                              containerClass="carousel-container"
                              removeArrowOnDeviceType={[]}
                              deviceType={this.props.deviceType}
                              dotListClass="custom-dot-list-style"
                              itemClass="carousel-item-padding-40-px"
                           >
                              {this.state.tab.map(function (item, i) {
                                 return (
                                    <>
                                       <Tab>
                                          <div onClick={() =>
                                             selectTab(i)} className={`food-category-preview ${i == selectedTab ? 'active' : ''}`}>
                                             <img src={item.tabImageUrl} />
                                             <p>{item.name}</p>
                                          </div>
                                       </Tab>
                                    </>
                                 );
                              })}
                           </Carousel>
                           <div className="food-category-panel food-tab-desktop">
                              {this.state.tab.map(function (tabs, j) {
                                 if (j == selectedTab) {
                                    return (
                                       <>
                                          <TabPanel>
                                             <div className="food-item-container">
                                                {tabs.concessionItems.map(function (item, i) {
                                                   return (
                                                      <>
                                                         <div className="food-item-preview">
                                                            <div className="food-item-img">
                                                               <img src={item.itemImageUrl} />
                                                            </div>
                                                            <div className="food-item-content">
                                                               {item.title != item.description && item.title != null ?
                                                                  <>
                                                                     <h4>{item.title}</h4>
                                                                     <p>{item.description}</p>
                                                                  </>
                                                                  :
                                                                  <h4>{item.description}</h4>
                                                               }
                                                               {/* 
                              <h4>{item.description}</h4>
                              */}
                                                               <div className="food-price-contain">
                                                                  <div className="food-price">
                                                                     <p>{item.itemPrice}</p>
                                                                     <button
                                                                        className="btnc btnc-primary btn-add-food"
                                                                        onClick={() =>
                                                                           openModal(item, item.foodtype)
                                                                        }
                                                                     >
                                                                        {localStorage.getItem("language") !==
                                                                           "ar" ? (
                                                                           "Add"
                                                                        ) : (
                                                                           <Text>يضيف</Text>
                                                                        )}
                                                                     </button>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </>
                                                   );
                                                })}
                                             </div>
                                          </TabPanel>
                                       </>
                                    );
                                 }
                              })}
                           </div>
                        </div>
                     </Tabs>
                  </div>
                  {/* mobile-view-tab */}
               </div>
            </div>
            {!this.state.onlyFood ?
               <>
                  <div className="booked-info-fixed  boocking-info">
                     {!this.state.showcart ? (
                        <div
                           className="scroll-up"
                           onClick={() => this.setState({ showcart: true })}
                        >
                           <img src={require("../../assets/Icon feather-shopping-cart.png").default} alt="" />
                        </div>
                     ) : (
                        <div
                           className="scroll-down"
                           onClick={() => this.setState({ showcart: false })}
                        >
                           <img src={require("../../assets/arrow-up.png").default} alt="" />
                        </div>
                     )}
                     {!this.state.showcart ? (



                        <div className="booked-collapsed ">
                           <div className="booked-movie-info ">
                              <div className="booked-movie-name">


                                 <span className="title-movie-info-namegit">
                                    {" "}
                                    {tckDetailsOutput.moviename
                                       ? tckDetailsOutput.moviename
                                       : ""}
                                 </span>
                                 <span className="title-star" style={{ backgroundColor: tckDetailsOutput.ratingColor }}>
                                    {tckDetailsOutput.mcensor ? tckDetailsOutput.mcensor : ""}
                                 </span>

                              </div>
                              <div className="booked-cinema">
                                 {" "}
                                 {tckDetailsOutput.cinemaname
                                    ? tckDetailsOutput.cinemaname
                                    : ""}
                              </div>
                              <div className="booked-cinema-time">
                                 <p>
                                    <span>
                                       {tckDetailsOutput.showTime
                                          ? tckDetailsOutput.showTime
                                          : ""}
                                    </span>
                                    <span>
                                       {" "}
                                       {tckDetailsOutput.showDate
                                          ? moment(tckDetailsOutput.showDate).format("dddd")
                                          : ""}
                                    </span>
                                    <span>
                                       {tckDetailsOutput.showDate
                                          ? tckDetailsOutput.showDate
                                          : ""}
                                    </span>
                                 </p>
                              </div>

                           </div>
                           <div className="combos-foods">

                              {this.state.concessionFoods.slice(-1).map(function (item, i) {
                                 fprice += item.priceInCents * item.quantity;
                                 if (!foodnotificationhide) {
                                    return (
                                       <div className="food-quantity-container food-new-quantity fade fade-in">
                                          <div className="food-quantity-img">
                                             <img
                                                src={item.itemImageUrl
                                                }
                                                alt=""
                                             />
                                          </div>
                                          <div className="food-quantity-name">

                                             {/* <h4><b>{item.title}</b> </h4> <p><b>{item.description}</b> <span>Added to cart</span></p> */}
                                             <h4>  {item.title != item.description && item.title != null ?
                                                <>
                                                   <b>{item.title}</b>

                                                </>
                                                :
                                                <b>{item.description}</b>
                                             } <span className="add-to-card">Added</span> </h4>
                                             {/* <p>{item.description}</p> */}
                                          </div>
                                       </div>
                                    );
                                 }

                              })}
                           </div>
                           <div className="booked-movie-total">
                              <p>
                                 TOTAL{" "}
                                 <span>
                                    {" "}
                                    {tckDetailsOutput.totalTicketPrice
                                       ? "KWD " + (parseFloat(tckDetailsOutput.totalTicketPriceInt / 100) + parseFloat(fpriceout / 100)).toFixed(3)
                                       : ""}{" "}
                                 </span>
                              </p>
                           </div>
                           {/* 
      <div className="booked-movie-time-remaining">
         <p>
            Time Remaining{" "}
            <span>
            {" "}
            {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}
            </span>
         </p>
      </div>
      */}
                           <div className="fixed-proceed-btn-contain">
                              {/* 
         <p>
            TIME REMAINING {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}
         </p>
         */}
                              {this.state.loading ? (
                                 <Loading />
                              ) : (
                                 <div className="proceed-food-container">
                                    {!this.state.onlyFood ?
                                       <p><span className="time-remaining"> {localStorage.getItem("language") !== "ar" ? (
                                          "TIME REMAINING"
                                       ) : (
                                          <Text>الوقت المتبقي</Text>
                                       )} </span>    {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}</p>
                                       : ''}
                                    <button
                                       className="btnc btnc-ghost btn-cancel"
                                       onClick={() => this.canceltrans()}
                                    >
                                       Cancel
                                    </button>

                                    {/* <button
                                 className="btnc btnc-ghost proceed-btn"
                                 onClick={() => this.newpayment()}
                              >
                                 Proceed
                              </button> */}

                                    <button
                                       className="btnc btnc-ghost proceed-btn"
                                       onClick={this.submitFood}
                                    >
                                       Proceed

                                    </button>


                                 </div>
                              )}
                           </div>
                        </div>

                     ) : (
                        ""
                     )}
                     {this.state.showcart ? (
                        <div className="booked-active ">
                           <div className="booked-info-full">
                              <div className="booked-movie-info">
                                 <h4 className="booking-info"> {localStorage.getItem("language") !== "ar" ? (
                                    "BOOKING INFORMATION"
                                 ) : (
                                    <Text>معلومات الحجز</Text>
                                 )}</h4>
                                 <div className="booked-movie-full-img">
                                    <img
                                       src={tckDetailsOutput.poster ? tckDetailsOutput.poster : ""}
                                    />
                                 </div>
                                 <div className="booked-movie-name movie-info-title">
                                    <span className="title-movie-info-namegit">
                                       {" "}
                                       {tckDetailsOutput.moviename
                                          ? tckDetailsOutput.moviename
                                          : ""}
                                    </span>
                                    <span className="title-star" style={{ backgroundColor: tckDetailsOutput.ratingColor }}>
                                       {tckDetailsOutput.mcensor ? tckDetailsOutput.mcensor : ""}
                                    </span>
                                 </div>
                                 <div className="booked-cinema-info">
                                    <div className="booked-cinema booked-font">
                                       <p> {localStorage.getItem("language") !== "ar" ? (
                                          "Location"
                                       ) : (
                                          <Text>موقع</Text>
                                       )} </p>
                                       <p>
                                          {" "}
                                          {tckDetailsOutput.cinemaname
                                             ? tckDetailsOutput.cinemaname
                                             : ""}
                                       </p>
                                    </div>
                                    <div className="screen-type booked-font">
                                       <p> {localStorage.getItem("language") !== "ar" ? (
                                          "Screen"
                                       ) : (
                                          <Text>شاشة</Text>
                                       )}</p>
                                       <p>
                                          {" "}
                                          {tckDetailsOutput.format ? tckDetailsOutput.format : ""}
                                       </p>
                                    </div>
                                    <div className="experience-type booked-font">
                                       <p> {localStorage.getItem("language") !== "ar" ? (
                                          "Experience"
                                       ) : (
                                          <Text>خبرة</Text>
                                       )}</p>
                                       <p>
                                          {tckDetailsOutput.experience
                                             ? tckDetailsOutput.experience
                                             : ""}
                                       </p>
                                    </div>
                                 </div>
                                 <div className="date-time-booked booked-font">
                                    <div className="time-date">
                                       <p> {localStorage.getItem("language") !== "ar" ? (
                                          "Date"
                                       ) : (
                                          <Text>تاريخ</Text>
                                       )}</p>
                                       <p>
                                          {" "}
                                          {tckDetailsOutput.showDate
                                             ? tckDetailsOutput.showDate
                                             : ""}
                                       </p>
                                    </div>
                                    <div className="time-date booked-font">

                                       <p> {localStorage.getItem("language") !== "ar" ? (
                                          "Time"
                                       ) : (
                                          <Text>وقت</Text>
                                       )}</p>
                                       <p>
                                          {" "}
                                          {tckDetailsOutput.showTime
                                             ? tckDetailsOutput.showTime
                                             : ""}
                                       </p>
                                    </div>
                                 </div>
                                 {/* 
            <div className="booked-cinema-ticket">
               <p>
                  <span>
                  {" "}
                  {tckDetailsOutput.numofseats
                  ? tckDetailsOutput.numofseats
                  : ""}{" "}
                  Tickets
                  </span>
               </p>
            </div>
            */}
                                 {/* 
            <div className="booked-cinema-time">
               <p>
                  <span>
                  {" "}
                  {tckDetailsOutput.showTime
                  ? tckDetailsOutput.showTime
                  : ""}
                  </span>
                  <span>
                  {" "}
                  {tckDetailsOutput.showDate
                  ? moment(tckDetailsOutput.showDate).format("dddd")
                  : ""}
                  </span>
                  <span>
                  {" "}
                  {tckDetailsOutput.showDate
                  ? tckDetailsOutput.showDate
                  : ""}
                  </span>
               </p>
            </div>
            */}
                                 {tckDetailsOutput.seatsArr ? (
                                    <div className="booked-cinema-ticket booked-new-seat">
                                       <p> {localStorage.getItem("language") !== "ar" ? (
                                          "Seat Number"
                                       ) : (
                                          <Text>رقم المقعد</Text>
                                       )}</p>


                                       <p>
                                          {tckDetailsOutput.seatsArr.map(function (item, i) {
                                             return <span>{item}</span>;
                                          })}
                                       </p>
                                    </div>
                                 ) : (
                                    ""
                                 )}
                              </div>
                           </div>
                           <div className="border-right-food"></div>
                           <div className="booked-food-full">
                              <div className="food-container-list food-info-section">

                                 <h4>  {localStorage.getItem("language") !== "ar" ? (
                                    "FOOD") : (
                                    <Text>طعام</Text>
                                 )}</h4>
                                 {this.state.concessionFoods.map(function (item, i) {
                                    fdprice += (item.priceInCents) * item.quantity;
                                    return (
                                       <div className="food-quantity-container">
                                          <div className="food-quantity-img">
                                             <img
                                                src={item.itemImageUrl
                                                }
                                                alt=""
                                             />
                                          </div>
                                          <div className="food-quantity-name">

                                             {item.title != item.description && item.title != null ?
                                                <>
                                                   <h5>{item.title}</h5>
                                                   <p>{item.description}</p>
                                                </>
                                                :
                                                <h5>{item.description}</h5>
                                             }
                                          </div>
                                          <div className="food-inc-btn">
                                             <p>
                                                KWD{" "}
                                                {(
                                                   (item.itemPrice.includes(" ") ? item.itemPrice.split(" ")[1] : item.itemPrice) * item.quantity
                                                ).toFixed(3)}
                                             </p>
                                             <div class="counter-button">
                                                <button onClick={() => decreaseFoodQuantity(i)}>
                                                   -
                                                </button>
                                                <input
                                                   type="text"
                                                   name="ticket-count"
                                                   id="ticket-count"
                                                   placeholder={item.quantity}
                                                />
                                                <button onClick={() => increaseFoodQuantity(i)}>
                                                   +
                                                </button>
                                             </div>
                                          </div>
                                          <div className="food-remove-btn">
                                             <a
                                                className="pointer-cursor"
                                                onClick={() => removeFood(i)}
                                             >
                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                                Remove
                                             </a>
                                          </div>
                                       </div>
                                    );
                                 })}
                              </div>
                              <div className="food-price-total">
                              </div>
                           </div>
                           <div className="border-right-food"></div>
                           <div className="booked-time-full payment-sumarry">
                              <h4> {localStorage.getItem("language") !== "ar" ? (
                                 "PAYMENT SUMMARY"
                              ) : (
                                 <Text>ملخص الدفع</Text>
                              )}</h4>
                              <div className="all-total-price">
                                 <div className="total-tickets-price ticket-new">
                                    <p>
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "Ticket Price"
                                       ) : (
                                          <Text>سعر التذكرة</Text>
                                       )} <span>{tckDetailsOutput.ticketPrice} x {tckDetailsOutput.numofseats}</span>
                                    </p>
                                    <p>
                                       {tckDetailsOutput.totalTicketPrice}
                                    </p>
                                 </div>
                                 <div className="total-tickets-price ticket-new">
                                    <p>
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "Food Price"
                                       ) : (
                                          <Text>سعر الغذاء</Text>
                                       )}{" "}
                                    </p>
                                    <p>
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "KWD "
                                       ) : (
                                          <Text>دينار كويتي</Text>
                                       )}{parseFloat(fdprice / 100).toFixed(3)}
                                    </p>
                                 </div>
                                 <div className="grand-total grand-new">
                                    <p>

                                       {localStorage.getItem("language") !== "ar" ? (
                                          "GRAND TOTAL"
                                       ) : (
                                          <Text>المجموع الإجمالي</Text>
                                       )}{" "}
                                    </p>
                                    <p>

                                       {" "}
                                       {tckDetailsOutput.totalTicketPrice
                                          ? "KWD " + (parseFloat(tckDetailsOutput.totalTicketPriceInt / 100) + parseFloat(fdprice / 100)).toFixed(3)
                                          : ""}{" "}
                                    </p>
                                 </div>
                              </div>



                              <div className="fixed-payment-contain" style={{ display: "none" }}>
                                 <h4>   {localStorage.getItem("language") !== "ar" ? (
                                    "PAYMENT METHOD"
                                 ) : (
                                    <Text>طريقة الدفع او السداد</Text>
                                 )}
                                 </h4>

                                 <div className="payment-options">
                                    <div
                                       className={`payment-option pointer-cursor ${this.state.selectedpayment == "Wallet"
                                          ? "payment-border"
                                          : ""
                                          }`}
                                       onClick={() =>
                                          this.setState({
                                             showcreditcard: false,
                                             selectedpayment: "Wallet",
                                          })
                                       }
                                    >
                                       <img
                                          src={
                                             require(`../../assets/${this.state.selectedpayment == "Wallet"
                                                ? "Group 3090(1).png"
                                                : "Group 3090.png"
                                                }`).default
                                          }
                                       />
                                       <p>Wallet</p>
                                    </div>
                                    <div
                                       className={`payment-option pointer-cursor ${this.state.selectedpayment == "Card"
                                          ? "payment-border"
                                          : ""
                                          }`}
                                       onClick={() =>
                                          this.setState({
                                             showcreditcard: true,
                                             selectedpayment: "Card",
                                          })
                                       }
                                    >
                                       <img
                                          src={
                                             require(`../../assets/${this.state.selectedpayment == "Card"
                                                ? "Group 3092(1).png"
                                                : "Group 3092.png"
                                                }`).default
                                          }
                                       />
                                       <p>Credit Card</p>
                                    </div>
                                    <div
                                       className={`payment-option pointer-cursor ${this.state.selectedpayment == "KNET"
                                          ? "payment-border"
                                          : ""
                                          }`}
                                       onClick={() =>
                                          this.setState({
                                             showcreditcard: false,
                                             selectedpayment: "KNET",
                                          })
                                       }
                                    >
                                       <img
                                          src={
                                             require(`../../assets/${this.state.selectedpayment == "KNET"
                                                ? "Group 3094(1).png"
                                                : "Group 3094.png"
                                                }`).default
                                          }
                                       />
                                       <p>KNET</p>
                                    </div>
                                 </div>
                                 {this.state.showcreditcard ? (
                                    <div className="card-details-container">
                                       <div className="input-box-card">
                                          <input
                                             type="text"
                                             name=""
                                             placeholder="Cardholder Name"
                                          />
                                       </div>
                                       <div className="input-box-card">
                                          <input type="text" name="" placeholder="Card Number" />
                                       </div>
                                       <div className="card-types">
                                          <img
                                             src={
                                                require("../../assets/express-icon (1).png").default
                                             }
                                          />
                                          &nbsp;
                                          <img
                                             src={
                                                require("../../assets/express-icon (2).png").default
                                             }
                                          />
                                          &nbsp;
                                          <img
                                             src={
                                                require("../../assets/express-icon (4).png").default
                                             }
                                          />
                                       </div>
                                       <div className="input-box-card">
                                          <div className="input-box-part">
                                             <input
                                                type="text"
                                                name=""
                                                placeholder="Expiry Date"
                                             />
                                          </div>
                                          <div className="input-box-part input-ccv">
                                             <img
                                                src={
                                                   require("../../assets/express-icon (3).png")
                                                      .default
                                                }
                                             />
                                             <input type="text" name="" placeholder="CCV" />
                                          </div>
                                       </div>
                                    </div>
                                 ) : (
                                    ""
                                 )}
                              </div>
                              <div className="fixed-proceed-btn-contain">
                                 {/* 
                                 <p>
                                    TIME REMAINING {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}
                                 </p>
                                 */}
                                 {this.state.loading ? (
                                    <Loading />
                                 ) : (
                                    <div className="proceed-food-container">
                                       {!this.state.onlyFood ?
                                          <p><span className="time-remaining"> {localStorage.getItem("language") !== "ar" ? (
                                             "TIME REMAINING"
                                          ) : (
                                             <Text>الوقت المتبقي</Text>
                                          )} </span>    {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}</p>
                                          : ''}
                                       <button
                                          className="btnc btnc-ghost btn-cancel"
                                          onClick={this.canceltrans}
                                       >
                                          Cancel
                                       </button>
                                       <button
                                          className="btnc btnc-ghost proceed-btn"
                                          onClick={this.submitFood}
                                       >
                                          Proceed
                                       </button>
                                    </div>
                                 )}
                              </div>
                           </div>
                        </div>
                     ) : (
                        ""
                     )}
                  </div>
                  <div className="booked-info-fixed-mobile">
                     <div className="fixed-mob-1">
                        <div className="cancel-img">
                           <img src={require("../../assets/Group 1807.png").default} />
                        </div>
                        <p>CANCEL</p>
                     </div>
                     {!this.state.onlyFood?
                     <div className="fixed-mob-1">
                        <p>TIME LEFT</p>
                        <p>
                           <b>{this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}</b>
                        </p>
                     </div>
                     :''}
                     <div className="fixed-mob-1">
                        <div className="cart-img">
                           <img
                              src={
                                 require("../../assets/Icon feather-shopping-cart.png")
                                    .default
                              }
                           />
                           <div className="cart-item">{this.state.concessionFoods.length}</div>
                        </div>
                     </div>
                     <div className="fixed-mob-1">
                        <button className=" btnc btnc-primary btnc-proceed" onClick={this.submitFood}>
                           Proceed
                        </button>
                     </div>
                  </div></> :

               <>
                  <>
                     <div className="booked-info-fixed  boocking-info">
                        {!this.state.showcart ? (
                           <div
                              className="scroll-up"
                              onClick={() => this.setState({ showcart: true })}
                           >
                              <img src={require("../../assets/Icon feather-shopping-cart.png").default} alt="" />
                           </div>
                        ) : (
                           <div
                              className="scroll-down"
                              onClick={() => this.setState({ showcart: false })}
                           >
                              <img src={require("../../assets/arrow-up.png").default} alt="" />
                           </div>
                        )}
                        {!this.state.showcart ? (



                           <div className="booked-collapsed ">

                              <div className="combos-foods">

                                 {this.state.concessionFoods.slice(-1).map(function (item, i) {
                                    fprice += item.priceInCents * item.quantity;
                                    if (!foodnotificationhide) {
                                       return (
                                          <div className="food-quantity-container food-new-quantity fade fade-in">
                                             <div className="food-quantity-img">
                                                <img
                                                   src={item.itemImageUrl
                                                   }
                                                   alt=""
                                                />
                                             </div>
                                             <div className="food-quantity-name">

                                                {/* <h4><b>{item.title}</b> </h4> <p><b>{item.description}</b> <span>Added to cart</span></p> */}
                                                <h4>  {item.title != item.description && item.title != null ?
                                                   <>
                                                      <b>{item.title}</b>

                                                   </>
                                                   :
                                                   <b>{item.description}</b>
                                                } <span className="add-to-card">Added</span> </h4>
                                                {/* <p>{item.description}</p> */}
                                             </div>
                                          </div>
                                       );
                                    }

                                 })}
                              </div>
                              <div className="booked-movie-total">
                                 <p>
                                    TOTAL{" "}
                                    <span>
                                       {" "}
                                       {"KWD " +
                                          (parseFloat(fpriceout / 100)).toFixed(3)
                                       }{" "}
                                    </span>
                                 </p>
                              </div>
                              {/* 
      <div className="booked-movie-time-remaining">
         <p>
            Time Remaining{" "}
            <span>
            {" "}
            {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}
            </span>
         </p>
      </div>
      */}
                              <div className="fixed-proceed-btn-contain">
                                 {/* 
         <p>
            TIME REMAINING {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}
         </p>
         */}
                                 {this.state.loading ? (
                                    <Loading />
                                 ) : (
                                    <div className="proceed-food-container">
                                       {!this.state.onlyFood ?
                                          <p><span className="time-remaining"> {localStorage.getItem("language") !== "ar" ? (
                                             "TIME REMAINING"
                                          ) : (
                                             <Text>الوقت المتبقي</Text>
                                          )} </span>      {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}</p>
                                          : ''}
                                       <button
                                          className="btnc btnc-ghost btn-cancel"
                                          onClick={() => this.canceltrans()}
                                       >
                                          Cancel
                                       </button>

                                       {/* <button
                                 className="btnc btnc-ghost proceed-btn"
                                 onClick={() => this.newpayment()}
                              >
                                 Proceed
                              </button> */}

                                       <button
                                          className="btnc btnc-ghost proceed-btn"
                                          onClick={this.submitFood}
                                       >
                                          Proceed

                                       </button>


                                    </div>
                                 )}
                              </div>
                           </div>

                        ) : (
                           ""
                        )}
                        {this.state.showcart ? (
                           <div className="booked-active ">

                              <div className="booked-food-full">
                                 <div className="food-container-list food-info-section">

                                    <h4>  {localStorage.getItem("language") !== "ar" ? (
                                       "FOOD") : (
                                       <Text>طعام</Text>
                                    )}</h4>
                                    {this.state.concessionFoods.map(function (item, i) {
                                       foodieprice += (item.priceInCents) * item.quantity;
                                       return (
                                          <div className="food-quantity-container">
                                             <div className="food-quantity-img">
                                                <img
                                                   src={item.itemImageUrl
                                                   }
                                                   alt=""
                                                />
                                             </div>
                                             <div className="food-quantity-name">

                                                {item.title != item.description && item.title != null ?
                                                   <>
                                                      <h5>{item.title}</h5>
                                                      <p>{item.description}</p>
                                                   </>
                                                   :
                                                   <h5>{item.description}</h5>
                                                }
                                             </div>
                                             <div className="food-inc-btn">
                                                <p>
                                                KWD{" "}
                                                {(
                                                   (item.itemPrice.includes(" ") ? item.itemPrice.split(" ")[1] : item.itemPrice) * item.quantity
                                                ).toFixed(3)}
                                                </p>
                                                <div class="counter-button">
                                                   <button onClick={() => decreaseFoodQuantity(i)}>
                                                      -
                                                   </button>
                                                   <input
                                                      type="text"
                                                      name="ticket-count"
                                                      id="ticket-count"
                                                      placeholder={item.quantity}
                                                   />
                                                   <button onClick={() => increaseFoodQuantity(i)}>
                                                      +
                                                   </button>
                                                </div>
                                             </div>
                                             <div className="food-remove-btn">
                                                <a
                                                   className="pointer-cursor"
                                                   onClick={() => removeFood(i)}
                                                >
                                                   <i class="fa fa-trash" aria-hidden="true"></i>
                                                   Remove
                                                </a>
                                             </div>
                                          </div>
                                       );
                                    })}
                                 </div>
                                 <div className="food-price-total">
                                 </div>
                              </div>
                              <div className="border-right-food"></div>
                              <div className="booked-time-full payment-sumarry">
                                 <h4> {localStorage.getItem("language") !== "ar" ? (
                                    "PAYMENT SUMMARY"
                                 ) : (
                                    <Text>ملخص الدفع</Text>
                                 )}</h4>
                                 <div className="all-total-price">

                                    <div className="total-tickets-price ticket-new">
                                       <p>
                                          {localStorage.getItem("language") !== "ar" ? (
                                             "Food Price"
                                          ) : (
                                             <Text>سعر الغذاء</Text>
                                          )}{" "}
                                       </p>
                                       <p>
                                          {localStorage.getItem("language") !== "ar" ? (
                                             "KWD "
                                          ) : (
                                             <Text>دينار كويتي</Text>
                                          )}{parseFloat(foodieprice / 100).toFixed(3)}
                                       </p>
                                    </div>
                                    <div className="grand-total grand-new">
                                       <p>

                                          {localStorage.getItem("language") !== "ar" ? (
                                             "GRAND TOTAL"
                                          ) : (
                                             <Text>المجموع الإجمالي</Text>
                                          )}{" "}
                                       </p>
                                       <p>
                                      
                                       {" "}
                                       {tckDetailsOutput.totalTicketPrice
                                          ? "KWD " + (parseFloat(tckDetailsOutput.totalTicketPriceInt / 100) + parseFloat(foodieprice / 100)).toFixed(3)
                                          : ""}{" "}
                                       </p>
                                    </div>
                                 </div>



                                 <div className="fixed-payment-contain" style={{ display: "none" }}>
                                    <h4>   {localStorage.getItem("language") !== "ar" ? (
                                       "PAYMENT METHOD"
                                    ) : (
                                       <Text>طريقة الدفع او السداد</Text>
                                    )}
                                    </h4>

                                    <div className="payment-options">
                                       <div
                                          className={`payment-option pointer-cursor ${this.state.selectedpayment == "Wallet"
                                             ? "payment-border"
                                             : ""
                                             }`}
                                          onClick={() =>
                                             this.setState({
                                                showcreditcard: false,
                                                selectedpayment: "Wallet",
                                             })
                                          }
                                       >
                                          <img
                                             src={
                                                require(`../../assets/${this.state.selectedpayment == "Wallet"
                                                   ? "Group 3090(1).png"
                                                   : "Group 3090.png"
                                                   }`).default
                                             }
                                          />
                                          <p>Wallet</p>
                                       </div>
                                       <div
                                          className={`payment-option pointer-cursor ${this.state.selectedpayment == "Card"
                                             ? "payment-border"
                                             : ""
                                             }`}
                                          onClick={() =>
                                             this.setState({
                                                showcreditcard: true,
                                                selectedpayment: "Card",
                                             })
                                          }
                                       >
                                          <img
                                             src={
                                                require(`../../assets/${this.state.selectedpayment == "Card"
                                                   ? "Group 3092(1).png"
                                                   : "Group 3092.png"
                                                   }`).default
                                             }
                                          />
                                          <p>Credit Card</p>
                                       </div>
                                       <div
                                          className={`payment-option pointer-cursor ${this.state.selectedpayment == "KNET"
                                             ? "payment-border"
                                             : ""
                                             }`}
                                          onClick={() =>
                                             this.setState({
                                                showcreditcard: false,
                                                selectedpayment: "KNET",
                                             })
                                          }
                                       >
                                          <img
                                             src={
                                                require(`../../assets/${this.state.selectedpayment == "KNET"
                                                   ? "Group 3094(1).png"
                                                   : "Group 3094.png"
                                                   }`).default
                                             }
                                          />
                                          <p>KNET</p>
                                       </div>
                                    </div>
                                    {this.state.showcreditcard ? (
                                       <div className="card-details-container">
                                          <div className="input-box-card">
                                             <input
                                                type="text"
                                                name=""
                                                placeholder="Cardholder Name"
                                             />
                                          </div>
                                          <div className="input-box-card">
                                             <input type="text" name="" placeholder="Card Number" />
                                          </div>
                                          <div className="card-types">
                                             <img
                                                src={
                                                   require("../../assets/express-icon (1).png").default
                                                }
                                             />
                                             &nbsp;
                                             <img
                                                src={
                                                   require("../../assets/express-icon (2).png").default
                                                }
                                             />
                                             &nbsp;
                                             <img
                                                src={
                                                   require("../../assets/express-icon (4).png").default
                                                }
                                             />
                                          </div>
                                          <div className="input-box-card">
                                             <div className="input-box-part">
                                                <input
                                                   type="text"
                                                   name=""
                                                   placeholder="Expiry Date"
                                                />
                                             </div>
                                             <div className="input-box-part input-ccv">
                                                <img
                                                   src={
                                                      require("../../assets/express-icon (3).png")
                                                         .default
                                                   }
                                                />
                                                <input type="text" name="" placeholder="CCV" />
                                             </div>
                                          </div>
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                 </div>
                                 <div className="fixed-proceed-btn-contain">
                                    {/* 
   <p>
      TIME REMAINING {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}
   </p>
   */}
                                    {this.state.loading ? (
                                       <Loading />
                                    ) : (
                                       <div className="proceed-food-container">
                                          {!this.state.onlyFood ?
                                             <p>   {localStorage.getItem("language") !== "ar" ? (
                                                "TIME REMAINING"
                                             ) : (
                                                <Text>الوقت المتبقي</Text>
                                             )} {this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}</p>
                                             : ''}
                                          <button
                                             className="btnc btnc-ghost btn-cancel"
                                             onClick={this.canceltrans}
                                          >
                                             Cancel
                                          </button>
                                          <button
                                             className="btnc btnc-ghost proceed-btn"
                                             onClick={this.submitFood}
                                          >
                                             Proceed
                                          </button>
                                       </div>
                                    )}
                                 </div>
                              </div>
                           </div>
                        ) : (
                           ""
                        )}
                     </div>
                     <div className="booked-info-fixed-mobile">
                        <div className="fixed-mob-1">
                           <div className="cancel-img">
                              <img src={require("../../assets/Group 1807.png").default} />
                           </div>
                           <p>CANCEL</p>
                        </div>
                        <div className="fixed-mob-1">
                           <p>TIME LEFT</p>
                           <p>
                              <b>{this.state.seconds > 0 ? <>   {this.state.time.m}:{this.state.time.s}</> : "00:00"}</b>
                           </p>
                        </div>
                        <div className="fixed-mob-1">
                           <div className="cart-img">
                              <img
                                 src={
                                    require("../../assets/Icon feather-shopping-cart.png")
                                       .default
                                 }
                              />
                              <div className="cart-item">{this.state.concessionFoods.length}</div>
                           </div>
                        </div>
                        <div className="fixed-mob-1">
                           <button className=" btnc btnc-primary btnc-proceed" onClick={this.submitFood}>
                              Proceed
                           </button>
                        </div>
                     </div></>
               </>
            }

            {
               this.state.showModalFood ? (
                  <>
                     <Modal
                        isOpen={this.state.showModalFood}
                        contentLabel="Example Modal"
                        portalClassName={`food-modal ${foodarabic}`}
                     >
                        {" "}
                        <button
                           className="trailer-cross"
                           onClick={() => this.setState({ showModalFood: false, quantity: 0 })}
                        >
                           <i class="fas fa-times"></i>
                        </button>
                        <div className="food-modal-content">
                           {
                              <div className="food-modal">
                                 <div className="combo-food">
                                    <img src={this.state.modalItem.itemImageUrl} />
                                    {this.state.modalItem.title != this.state.modalItem.description && this.state.modalItem.title != null ?
                                       <>
                                          <h4>{this.state.modalItem.title}</h4>
                                          <p>{this.state.modalItem.description}</p>
                                       </>
                                       :
                                       <h4>{this.state.modalItem.description}</h4>
                                    }

                                    <p>{this.state.modalItem.itemPrice}</p>
                                 </div>
                                 <div className="food-info">
                                    {this.state.modalItem.foodtype == "Flavour" ? (
                                       <>
                                          <div className="flavour-select-container">
                                             <div className={`pop-corn-section`}
                                             >
                                                <div className="selected-food">
                                                   {this.state.modalItem.alternateItems.map(function (
                                                      item,
                                                      i
                                                   ) {
                                                      let active = "";
                                                      if (item.description == selectedFlavor)
                                                         active = "active-img";
                                                      return (
                                                         <div className={`pop-corn-imgs drink images ${active}`} onClick={() =>
                                                            selectflavor(item.id,
                                                               item.description,
                                                               item.priceInCents,
                                                               item.headOfficeItemCode,
                                                               item.descriptionAlt,
                                                               item.itemImageUrl
                                                            )
                                                         }>
                                                            <h5>{item.title}</h5>
                                                            <p>{item.description}</p>
                                                            <img src={item.itemImageUrl} />
                                                         </div>
                                                      )
                                                   })}
                                                </div>
                                             </div>
                                          </div>
                                          <div className="selected-quantity">
                                             <h5>Select Quantity</h5>
                                             <div className="total-price">
                                                <div className="selected-info">
                                                   <div class="counter-button"
                                                   ><button onClick={() => decreasequantity()}><i class="fa fa-minus" aria-hidden="true"></i></button>
                                                      <input type="text" name="ticket-count" id="ticket-count" placeholder={this.state.quantity}></input>
                                                      <button onClick={() => increasequantity()}
                                                      ><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                   </div>
                                                </div>
                                                <div className="total-price">
                                                   <p>Total price <span>
                                                      KWD{" "}
                                                      {this.state.quantity > 0
                                                         ? (parseFloat(this.state.quantity *
                                                            this.state.modalItem.priceInCents) /
                                                            100).toFixed(3)
                                                         : parseFloat(this.state.modalItem.priceInCents / 100).toFixed(3)}</span>
                                                      {this.flavorvalidator.message(
                                                         "quantity",
                                                         this.state.quantity,
                                                         "numeric|min:1,num"
                                                      )}
                                                   </p>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="btn-add">
                                             <div class="proceed-food-container">
                                                <button class="btnc btnc-ghost cancel-btn" onClick={() => this.setState({ showModalFood: false, quantity: 0 })}
                                                >Cancel</button>
                                                <button class="btnc btnc-ghost done-btn" disabled={this.state.selectioncomplete && this.state.quantity > 0 ? false : true} onClick={() =>
                                                   this.addConcessionFoods(
                                                      "Flavour",
                                                      this.state.modalItem.id,                                                     
                                                       this.state.modalItem.title,
                                                      this.state.modalItem.description + "(" + selectedFlavor + ")",
                                                      this.state.modalItem.descriptionAlt + "(" + this.state.selectedFlavorarabic + ")",
                                                      this.state.selectedheadOfficeItemCode,
                                                      this.state.modalItem.itemPrice,
                                                      "Flavour",
                                                      this.state.modalItem.parentId,
                                                      this.state.modalItem.priceInCents,
                                                      this.state.quantity,
                                                      this.state.foodimage

                                                   )
                                                }>Done</button>
                                             </div>
                                          </div>
                                       </>
                                    ) : ''}
                                    {this.state.modalItem.foodtype == "combo" ?

                                       (
                                          <>
                                             <div className="flavour-select-container">

                                                {this.state.modalItem.packageChildItems.map(function (
                                                   item,
                                                   i
                                                ) {

                                                   return (<>
                                                      <div className={`pop-corn-section`}
                                                      >
                                                         <h5>{item.description}</h5>
                                                         <div className="selected-food">
                                                            {item.alternateItems.map(function (
                                                               food,
                                                               index
                                                            ) {
                                                               let active = "";
                                                               if (selectedCombo.indexOf(food) > -1)
                                                                  active = "active-img";
                                                               return (
                                                                  <div className={`pop-corn-imgs drink images food-combo ${active}`} onClick={() =>
                                                                     selectCombo(food, i, packageChildItemsLength)
                                                                  }>
                                                                     <img src={food.itemImageUrl} />
                                                                     <h5>{food.description}</h5>
                                                                  </div>
                                                               )
                                                            })}
                                                         </div>
                                                      </div>
                                                   </>)
                                                })}
                                             </div>
                                             <div className="selected-quantity">
                                                <h5>Selected Quantity</h5>
                                                <div className="total-price">
                                                   <div className="selected-info">
                                                      <div class="counter-button"
                                                      ><button onClick={() => decreasequantity()}>
                                                            <i class="fa fa-minus" aria-hidden="true"></i></button>
                                                         <input type="text" name="ticket-count" id="ticket-count" placeholder={this.state.quantity}></input>
                                                         <button onClick={() => increasequantity()}
                                                         ><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                      </div>
                                                   </div>
                                                   <div className="total-price">

                                                      <p>Total prices <span>
                                                         KWD{" "}
                                                         {this.state.quantity > 0
                                                            ? parseFloat((this.state.quantity *
                                                               this.state.modalItem.priceInCents) /
                                                               100).toFixed(3)
                                                            : parseFloat(this.state.modalItem.priceInCents / 100).toFixed(3)}</span>
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="btn-add">
                                                <div class="proceed-food-container">
                                                   <button class="btnc btnc-ghost cancel-btn" onClick={() => this.setState({ showModalFood: false, quantity: 0 , selectedCombo: [], selectioncomplete : false})}
                                                   >Cancel {this.state.selectioncomplete}</button>
                                                   <button class="btnc btnc-ghost done-btn" disabled={this.state.selectioncomplete && this.state.quantity > 0 ? false : true} onClick={() =>
                                                      this.addConcessionFoods(
                                                         "Combo",
                                                         this.state.modalItem.id,
                                                         this.state.modalItem.title,

                                                         this.state.modalItem.description,
                                                         this.state.modalItem.descriptionAlt,
                                                         this.state.modalItem.headOfficeItemCode,
                                                         this.state.modalItem.itemPrice,
                                                         this.state.modalItem.itemType,
                                                         this.state.modalItem.parentId,
                                                         this.state.modalItem.priceInCents,
                                                         this.state.quantity,
                                                         this.state.modalItem.itemImageUrl
                                                      )
                                                   }>Done</button>
                                                </div>
                                             </div>
                                          </>
                                       ) : ''}
                                 </div>
                              </div>
                           }
                        </div>
                     </Modal>
                  </>
               ) : (
                  ""
               )
            }
         </>
      );
   }
}
export default Food;